import { Component, OnInit } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { SidebarMenuModule } from '../sidebar-menu/sidebar-menu.module';
import { ApiService } from '../../../core/services/api.service';
import { UtilityService } from '../../../core/services/utility.service';
import { FormGroupDirective } from '@angular/forms';
import { UserService } from '../../../core/services/user.service';
import CreditCardSpace from 'credit-card-space';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-saved-card',
  templateUrl: './saved-card.component.html',
  styleUrls: ['./saved-card.component.scss']
})
export class SavedCardComponent implements OnInit {
  cards: any = [];
  result: boolean;
  errors: any;
  success: any;
  loading: Boolean;
  cardNumber: any;
  nameHolder: any;
  expDate: any;
  errorForYear: boolean;
  submitted: any = false;
  constructor(
    private apiService: ApiService,
    private utilityService: UtilityService,
    private userService: UserService,
    private toastrService: ToastrService
  ) {
    if (!localStorage.getItem('currentUser')) {
      this.userService.redirectUrl('profile/saved-card');
      this.userService.displayPopup('sign-in');
    }
  }

  ngOnInit() {
    this.loading = true;
    this.getCards();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  // open add new card popup
  addNewCardPopup() {
    const elements = document.getElementsByClassName('addnew-card-popup');
    elements[0].classList.add('open');
    const element = document.getElementsByTagName('body');
    element[0].classList.add('modal-popup');
  }

  closeNewCardPopup() {
    const elements = document.getElementsByClassName('addnew-card-popup');
    elements[0].classList.remove('open');
    const element = document.getElementsByTagName('body');
    element[0].classList.remove('modal-popup');
  }
  inputValidator(event: any) {
    const pattern = /^[0-9]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
    }
    // const input = document.querySelector('#card_number');
    // const ccSpace = new CreditCardSpace(input);
    // this.cardNumber = ccSpace.value();
  }
  expDateValidator(event: any) {
    const pattern = /^[0-9$/]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
    }
    this.checkExpDate(event);
  }
  getCards() {
    this.apiService.callGetAPI('v2/cards', environment.paymentApiUrl).subscribe(
      (cards) => {
        this.cards = cards['response'].cards;
        this.loading = false;
      },
      (error) => {
        this.apiService.failureResponse(error, true);
      }
    );
  }
  deleteCard(id, index) {
    this.apiService
      .callDeleteAPI('v2/cards?id=' + id, environment.paymentApiUrl)
      .subscribe(
        (response) => {
          this.cards.splice(index, 1);
          this.apiService.successResponse(response);
        },
        (error) => {
          this.result = false;
          this.errors = this.apiService.failureResponse(error);
          if (this.errors.commonError) {
            this.result = true;
            this.success = this.errors.commonError;
            this.utilityService.VplyToaster();
          }
        }
      );
  }
  checkExpDate(event) {
    if (this.expDate !== undefined) {
      if (
        this.expDate.length === 2 &&
        this.expDate.length < 3 &&
        event.keyCode !== 8 &&
        event.keyCode !== 191
      ) {
        this.expDate += '/';
      }
      if (event.keyCode === 8 && this.expDate.length === 3) {
        this.expDate = this.expDate.substring(0, this.expDate.length - 1);
      }
      const monthDate = this.expDate.split('/');
      const month = monthDate[0];
      const year = monthDate[1];
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear().toString().substr(-2);
      const currentMonth = currentDate.getMonth();
      if (currentYear > year) {
        this.errorForYear = true;
      } else if (currentYear === year) {
        if (currentMonth + 1 > month) {
          this.errorForYear = true;
        } else {
          this.errorForYear = false;
        }
      } else {
        this.errorForYear = false;
      }
    }
  }
  addCard(form: FormGroupDirective) {
    this.submitted = true;
    const monthDate = this.expDate.split('/');
    const month = monthDate[0];
    const year = monthDate[1];
    const type = 'credit/debit';
    this.cards = [];
    const cvv = 123;
    if (this.errorForYear === false) {
      this.apiService
        .callPostAPI(
          'v2/cards',
          {
            name: this.nameHolder,
            card_number: this.cardNumber,
            month: month,
            year: year,
            type: type,
            cvv: cvv
          },
          environment.paymentApiUrl
        )
        .subscribe(
          (data) => {
            form.resetForm();
            this.submitted = false;
            this.getCards();
            this.closeNewCardPopup();
            this.apiService.successResponse(data);
          },
          (error) => {
            this.toastrService.error('', error.error.message.name[0]);
            this.nameHolder = '';
            this.submitted = false;
            this.apiService.failureResponse(error);
          }
        );
    }
  }
}
