import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../core/auth/auth.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../core/services/api.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  login_type: Boolean = true;
  routeSub: any;
  is_default_profile: any = 0;
  screens:any;
  localUser: any;
  constructor(private authService: AuthService, private router: Router, private apiService: ApiService,) {
   
    const userData = this.authService.decryption(
      localStorage.getItem('currentUser')
    );
    if (userData) {
      this.login_type =
        userData['response'].login_type === 'Web' ? true : false;
    }
    this.is_default_profile = Number(localStorage.getItem('is_default_profile'));
  }

  ngOnInit() {
       this.getProfile();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }
  getProfile() {
    this.localUser = this.authService.decryption();
    this.apiService.callGetAPI('v2/profile', environment.userApiUrl).subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.screens = data['response'].screen;
        }
      },
      (error) => {
        this.apiService.failureResponse(error, true);
      }
    );
  }
}
