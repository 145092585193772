// import {
//     Directive,
//     ElementRef,
//     Renderer2,
//     HostListener,
//     forwardRef,
//     Input,
//     OnInit,
//     HostBinding
// } from '@angular/core';

// import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
// import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';


// @Directive({
//     selector: '[appPhoneNumber]',
//     providers: [{ provide: NG_VALIDATORS, useExisting: PhoneNumberValidatorDirective, multi: true }]
// })

// export class PhoneNumberValidatorDirective implements Validator {
//     validate(control: AbstractControl): ValidationErrors | null {
//         const hubtelPhone: string = control.value;

//         if (hubtelPhone && !hubtelPhone.startsWith('233')) {
//             return { 'invalidPhoneNumber': true };
//         }

//         return null;
//     }
// }


import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appPhoneNumberValidator]'
})
export class PhoneNumberValidatorDirective {
    constructor(private el: ElementRef) { }

    @HostListener('input', ['$event']) onInput(event: any): void {
      
        const inputValue: string = this.el.nativeElement.value;

        // Validate the phone number format (start with '233' and have additional digits)
        const isValid = /^233\d{9,10}$/.test(inputValue);

        if (!isValid) {
            this.el.nativeElement.value = inputValue.slice(0, -1); // Remove the last character if not valid
        }
    }
}