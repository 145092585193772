import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { AuthService } from '../../core/auth/auth.service';
import { UserService } from '../../core/services/user.service';
import { NgForm, EmailValidator } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import disableScroll from 'disable-scroll';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

import { PLATFORM_ID, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  firstName: string;
  lastName: string;
  email: EmailValidator;
  password: string;
  confirmPassword: string;
  mobile: number;
  date: Date;
  touched = false;
  taken: boolean;
  error: any = {};
  title = 'SIGN UP';
  show: Boolean = false;
  submitted: any = false;
  checkbox: Boolean = false;
  phoneError: Boolean = false;
  otp: Boolean = false;
  otp_value: string;

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private toasterService: ToastrService,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  ngOnInit() {
    this.translate.get('SIGN_UP.CREATE_ACCOUNT').subscribe(res => {
      this.title = res;
    });
  }

  onSignUp(form: NgForm) {
   
    this.touched = true;
    this.phoneError = false;



    if (form.valid) {
      this.submitted = true;
      this.translate.get('SIGN_UP.CREATE_ACCOUNT').subscribe(res => {
        this.title = res + '...';
      });

      if (/^\+233\d{9}/.test(form.value.email)) {
    


        this.apiService.callPostAPI(
          'v2/auth/register',
          {
            login_type: 'normal',
            acesstype: 'web',
            phone: form.value.email,
            password: form.value.password,
            password_confirmation: form.value.password,
            name: form.value.firstName
          }, environment.userApiUrl)
          .subscribe(
            data => {
              this.submitted = false;
              if (data['statusCode'] === 200 && data['error'] === false) {

                this.apiService.callPostAPI('v2/auth/send/otp', {
                  phone_number: form.value.email,
                }, environment.userApiUrl)
                  .subscribe(
                    data => {
                      if (data['statusCode'] === 200 && data['error'] === false) {
                        this.submitted = true;
                        this.otp = true;

                      } else {

                        this.submitted = false;
                      }
                    },
                    error => {
                      this.submitted = false;
                      this.toasterService.error('', 'Could not send OTP to mobile number');
                    });

              }
            },
            error => {
     
              this.toasterService.error('', "The phone has already been taken");
              this.submitted = false;
              this.translate.get('SIGN_UP.CREATE_ACCOUNT').subscribe(res => {
                this.title = res;
              });
              if (error.error.statusCode === 422) {
                if (typeof error.error.message === 'string' || error.error.message instanceof String) {
                  this.toasterService.error('', error.error.message);
                } else {
                  this.error = error.error.message;
                }
              } else {
                this.error = {};
                this.toasterService.error('', (error.error.message) ? error.error.message : error.message);
              }
            });

      }
      else if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(form.value.email)) {
      


        this.apiService.callPostAPI(
          'v2/auth/register',
          {
            login_type: 'normal',
            acesstype: 'web',
            email: form.value.email,
            password: form.value.password,
            password_confirmation: form.value.password,
            name: form.value.firstName
          }, environment.userApiUrl)
          .subscribe(
            data => {
              this.submitted = false;
              if (data['statusCode'] === 200 && data['error'] === false) {

                this.toasterService.success('', data['message'], {
                  timeOut: 15000,
                });
                this.userService.displayPopup('colse');
                if (isPlatformBrowser(this.platformId)) {
                  disableScroll.off();
                }
                this.translate.get('SIGN_UP.CREATE_ACCOUNT').subscribe(res => {
                  this.title = res;
                });

                this.userService.nextPage.subscribe(
                  nextUrl => {
                    if (!localStorage.getItem('myUrl')) {
                      if (nextUrl.indexOf('video') !== -1) {
                        window.location.reload();
                      } else {
                        this.router.navigate([nextUrl]);
                      }
                    } else if (localStorage.getItem('myUrl')) {
                      if (data['response'].is_subscribed === 0) {
                        localStorage.setItem('subscribeUrl', window.location.pathname);
                        this.router.navigate(['subscription']);
                        localStorage.removeItem('myUrl');
                      } else if (data['response'].is_subscribed === 1) {
                        this.router.navigate([window.location.pathname.replace('video/', 'watch/')]);
                        localStorage.removeItem('myUrl');
                      }
                    }
                  });
              }
            },
            error => {
              this.submitted = false;
              this.translate.get('SIGN_UP.CREATE_ACCOUNT').subscribe(res => {
                this.title = res;
              });
              if (error.error.statusCode === 422) {
                if (typeof error.error.message === 'string' || error.error.message instanceof String) {
                  this.toasterService.error('', error.error.message);
                } else {
                  this.error = error.error.message;
                }
              } else {
                this.error = {};
                this.toasterService.error('', (error.error.message) ? error.error.message : error.message);
              }
            });

      }
      else {
        this.phoneError = true;
        
      }





      // this.apiService.callPostAPI(
      //   'v2/auth/register',
      //   {
      //     login_type: 'normal',
      //     acesstype: 'web',
      //     email: form.value.email,
      //     password: form.value.password,
      //     password_confirmation: form.value.password,
      //     name: form.value.firstName
      //   }, environment.userApiUrl)
      //   .subscribe(
      //     data => {
      //       this.submitted = false;
      //       if (data['statusCode'] === 200 && data['error'] === false) {

      //         this.toasterService.success('', data['message'], {
      //           timeOut: 15000,
      //         });
      //         this.userService.displayPopup('colse');
      //         disableScroll.off();
      //         this.translate.get('SIGN_UP.CREATE_ACCOUNT').subscribe(res => {
      //           this.title = res;
      //         });

      //         this.userService.nextPage.subscribe(
      //           nextUrl => {
      //             if (!localStorage.getItem('myUrl')) {
      //               if (nextUrl.indexOf('video') !== -1) {
      //                 window.location.reload();
      //               } else {
      //                 this.router.navigate([nextUrl]);
      //               }
      //             } else if (localStorage.getItem('myUrl')) {
      //               if (data['response'].is_subscribed === 0) {
      //                 localStorage.setItem('subscribeUrl', window.location.pathname);
      //                 this.router.navigate(['subscription']);
      //                 localStorage.removeItem('myUrl');
      //               } else if (data['response'].is_subscribed === 1) {
      //                 this.router.navigate([window.location.pathname.replace('video/', 'watch/')]);
      //                 localStorage.removeItem('myUrl');
      //               }
      //             }
      //           });
      //       }
      //     },
      //     error => {
      //       this.submitted = false;
      //       this.translate.get('SIGN_UP.CREATE_ACCOUNT').subscribe(res => {
      //         this.title = res;
      //       });
      //       if (error.error.statusCode === 422) {
      //         if (typeof error.error.message === 'string' || error.error.message instanceof String) {
      //           this.toasterService.error('', error.error.message);
      //         } else {
      //           this.error = error.error.message;
      //         }
      //       } else {
      //         this.error = {};
      //         this.toasterService.error('', (error.error.message) ? error.error.message : error.message);
      //       }
      //     });
    }
  }

  toPassword() {
    this.otp_value
    this.email

    console.log("OTP VALUE::", this.otp_value, "EMail", this.email)

    this.apiService.callPostAPI('v2/auth/verify/otp', {
      phone_number: this.email,
      otp: this.otp_value,
      activate: true
    }, environment.userApiUrl)
      .subscribe(
        data => {
          this.submitted = false;
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.otp = false;
            this.toasterService.success('', data['message'], {
              timeOut: 15000,
            });
            this.userService.displayPopup('colse');
            if (isPlatformBrowser(this.platformId)) {
              disableScroll.off();
            }
            this.translate.get('SIGN_UP.CREATE_ACCOUNT').subscribe(res => {
              this.title = res;
            });

            this.userService.nextPage.subscribe(
              nextUrl => {
                if (!localStorage.getItem('myUrl')) {
                  if (nextUrl.indexOf('video') !== -1) {
                    window.location.reload();
                  } else {
                    this.router.navigate([nextUrl]);
                  }
                } else if (localStorage.getItem('myUrl')) {
                  if (data['response'].is_subscribed === 0) {
                    localStorage.setItem('subscribeUrl', window.location.pathname);
                    this.router.navigate(['subscription']);
                    localStorage.removeItem('myUrl');
                  } else if (data['response'].is_subscribed === 1) {
                    this.router.navigate([window.location.pathname.replace('video/', 'watch/')]);
                    localStorage.removeItem('myUrl');
                  }
                }
              });

          } else {
            this.submitted = false;
          }
        },
        error => {
          this.submitted = false;
          this.toasterService.error('', 'OTP could not be verified. Kindly try again');
        });


  }

  onClose() {
    if (isPlatformBrowser(this.platformId)) {
      if (isPlatformBrowser(this.platformId)) {
        disableScroll.off();
      }
    }
    if (this.route.snapshot['_routerState'].url === '/sign-up') {
      this.router.navigate(['home']);
    }
    this.userService.displayPopup('colse');
  }

  onClick(value: string) {
    if (value === 'sign-in') {
      this.userService.displayPopup('sign-in');
    }
  }
  onChange(e) {
    this.error = {};
  }
  toggleShowPassword() {
    this.show = !this.show;
  }
}
