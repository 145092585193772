import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VideoShareService {

  constructor() { }
  private popup = new BehaviorSubject<any>('close');
  display = this.popup.asObservable();
  private getSliderMovedItems = new BehaviorSubject<any>('');
  sliderMovedItems = this.getSliderMovedItems.asObservable();
  private getSliderRowItemsCount = new BehaviorSubject<any>('');
  sliderRowItemsCount = this.getSliderRowItemsCount.asObservable();
  // video share popup
  displayPopup(data: any) {
    this.popup.next(data);
  }
  setSliderMovedItems(data: any) {
    this.getSliderMovedItems.next(data);
  }
  setSliderRowItems(data: any) {
    this.getSliderRowItemsCount.next(data);
  }
}
