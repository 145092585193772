import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'numberConversion', pure: false})
export class ViewCount implements PipeTransform {
  constructor () { }

  transform(num) {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0/, '') + 'B';
   }
   if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0/, '') + 'M';
   }
   if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0/, '') + 'K';
   }
   return num;
  }
}
