const apiDomain = 'https://api.homitv.com/';
const appDomain = 'https://homitv.com/';
const webRTCHost = 'webrtc.vplayed.com';

export const environment = {
    production: true,
    userApiUrl: `${apiDomain}users/api/`,
    apiUrl: `${apiDomain}medias/api/`,
    userActionApiUrl: `${apiDomain}useractions/api/`,
    paymentApiUrl: `${apiDomain}payments/api/`,
    notificationApiUrl: `${apiDomain}notifications/api/`,
    commonApiUrl: `${apiDomain}common/api/`,
    searchApiUrl: `${apiDomain}search/elastic/`,
    analyticsApiUrl: `${apiDomain}analytics/api/`,
    domain: appDomain,
    webRTCBroadcastDomain: `https://${webRTCHost}:5443/`,
    webRTCSocketUrl: `wss://${webRTCHost}:5443/WebRTCAppEE/websocket`,
    socketUrl: 'wss://admin.vplayed.demo.contus.us/ws/',
    encryption_key: 'vplay@contus',
    GoogleOAuthClientId: '136707794634-rvj0sc137fksclhuf9b0nc599iopvnpi.apps.googleusercontent.com',
    FacebookAppId: '647059733768993',
    googleRecaptcha: '6Ld6TtQZAAAAAEXyWthaQ3l313Qpozdmv8djq6dq',
    defaultImage: 'assets/images/blank_new.png',
    crypto_iv: 'a32b97d84acba36667c7709de74fa510',
    crypto_key: '2f00f5fbea3c1c77e8d3e5536021fc1d46e803670879e01e9de64b387ea4dc68',
    CURRENCY_SYMBOL: '$',
    STRIPE_PUBLIC_KEY: 'pk_live_51JpGQZAUgY0dpQL2TJBoy4qpWV678KWxMfV5YCUntEiNHkzHQ35fqpwO6xd9fDlcnUR5Go2oqPDeMrcfPXbNkk4800w3tQG6gd'
};

