
import { Injectable, RendererFactory2, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from

  '@angular/common/http';

import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private renderer;
  constructor(private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document, private http: HttpClient, private dialog: MatDialog) { 
    this.renderer = this.rendererFactory.createRenderer(null, null);
    }
    // Observable User details
    private currentUser = new BehaviorSubject<any>(localStorage.getItem('currentUser'));
    localUser = this.currentUser.asObservable();

    // Observable popup details
    private popup = new BehaviorSubject<any>('close');
    display = this.popup.asObservable();

    // Observable popup details
    private sharePopup = new BehaviorSubject<any>('close');
    displayShare = this.sharePopup.asObservable();


    // Observable popup details
    private url = new BehaviorSubject<any>('home');
    nextPage = this.url.asObservable();

    // user details
    userData(data: any) {
      this.currentUser.next(data);
    }

    // login, register and forget password popup
    displayPopup(data: any) {
      this.popup.next(data);
    }

    // login, register and forget password popup
    displaySharePopup(data: any) {
      this.sharePopup.next(data);
    }

    // redirect next page
  redirectUrl(data: any) {
     
      this.url.next(data);
    }

  loadScript(scriptUrl: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
       const script = this.renderer.createElement('script');
      //const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'script';
      script.src = scriptUrl;
       //script.onload = resolve;
      // script.onerror = reject;
      // script.onload = () => {
      //  // this.renderer.appendChild(document.body, script);
      //   // Assuming you have a Promise and a resolve function
      //   resolve(); // or resolve(someValue);
      // }
      script.onload = () => {
      
        //document.body.appendChild(script);
      //  const target2 = document.body.appendChild(script);
        const target2 = this.renderer.appendChild(document.body, script)
     
        resolve()
      };

      script.onerror = () => {
        console.error('Error loading external script');
        reject();
      };
     
     this.renderer.appendChild(document.body, script)
     // const target1 = document.body.appendChild(script);
    });
  }


  fetchScriptContent(url: string): Promise<string> {
    return this.http.get(url, { responseType: 'text' }).toPromise();
  }



  // openModal(content: string): void {
  //   this.dialog.open(DemoComponent, {
  //     width: '400px',
  //     data: { content },
  //   });
  // }
    
}
