import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'strremovewhitespace', pure: false})
export class StringRemoveWhitespace implements PipeTransform {
  constructor () { }

  transform(string) {
    if (string) {
      string = string.toLowerCase();
      return string.replace(/ /g, '-');
    }
  }
}
