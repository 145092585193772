




import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[appEmailOrPhoneValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: EmailOrPhoneValidatorDirective, multi: true }]
})
export class EmailOrPhoneValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return emailOrPhoneValidator()(control);
  }
}

// Custom validator function to validate email or phone
export function emailOrPhoneValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null; // Don't validate empty values
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phonePattern = /^[0-9]{10}$/;

    if (emailPattern.test(value) || phonePattern.test(value)) {
      return null; // Validation passed
    } else {
      return { 'invalidFormat': true }; // Validation failed
    }
  };
}

