import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { AuthService } from '../../../core/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  selected: Boolean;
  value = 0;
  comment: any;
  customerDevices: any[];
  autoplayInput: any = localStorage.getItem('autoPlay')
    ? JSON.parse(localStorage.getItem('autoPlay'))
    : true;
  commentValue: Number = 0;
  yes: any = 'Yes';
  is_subscribed: any;
  language: any;
  DEVICE_RESTRICTION_TYPE: string;
  revokeDeviceDetails: any;
  revokeDeviceMsgError: Boolean = false;
  user_type: any;
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private translate: TranslateService,
    private toasterService: ToastrService,
  ) {
    if (localStorage.getItem('currentLanguage')) {
      this.language = localStorage.getItem('currentLanguage');
    } else {
      this.language = 'en';
    }
    this.translate.get('SETTINGS.YES').subscribe((res) => {
      this.yes = res;
    });
  }
  ngOnInit() {
    this.getSettings();
    this.getCustomerDeviceList();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  // open cancel subscription popup
  cancelSubscriptionPopup() {

   
    // if (this.user_type && this.user_type === 'web' ) {
      const elements = document.getElementsByClassName('cancel-subs-popup');
      elements[0].classList.add('open');
      const element = document.getElementsByTagName('body');
      element[0].classList.add('modal-popup');
      localStorage.removeItem('couponResponse');
    // } else {
      
    //   const elements = document.getElementsByClassName('alert-user-popup');
    //   elements[0].classList.add('open');
    //   const element = document.getElementsByTagName('body');
    //   element[0].classList.add('modal-popup');
    //   localStorage.removeItem('couponResponse');
    // }
  }
  cancelSubscription() {
    const elements = document.getElementsByClassName('cancel-subs-popup');
    elements[0].classList.remove('open');
    const element = document.getElementsByTagName('body');
    element[0].classList.remove('modal-popup');
    localStorage.removeItem('couponResponse');
  }
  closeAlert() {
    const elements = document.getElementsByClassName('alert-user-popup');
    elements[0].classList.remove('open');
    const element = document.getElementsByTagName('body');
    element[0].classList.remove('modal-popup');
    localStorage.removeItem('couponResponse');
  }

  toggleNotifications() {
    this.selected ? (this.value = 1) : (this.value = 0);
    this.updateSettings();
  }
  toggleCommentNotification() {
    this.comment ? (this.commentValue = 1) : (this.commentValue = 0);
    this.updateSettings();
  }

  getCustomerDeviceList() {
    this.apiService
      .callPostAPI(
        'v2/customer-device-list',
        { reply_comment: this.commentValue, new_video: this.value },
        environment.userApiUrl
      )
      .subscribe(
        (data: any) => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.customerDevices = data.response['customerDeviceList'];
            this.DEVICE_RESTRICTION_TYPE = data.response['device_restriction_type'];
            this.revokeDeviceDetails = null;
          }
        },
        (error) => {
          this.apiService.failureResponse(error);
        }
      );
  }

  updateSettings() {
    this.apiService
      .callPostAPI(
        'v2/notification/settings',
        { reply_comment: this.commentValue, new_video: this.value },
        environment.notificationApiUrl
      )
      .subscribe(
        (data) => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.apiService.successResponse(data);
          }
        },
        (error) => {
          this.apiService.failureResponse(error);
        }
      );
  }
  getSettings() {
    this.apiService
      .callPostAPI(
        'v2/notification/settings',
        {},
        environment.notificationApiUrl
      )
      .subscribe(
        (response) => {
          this.is_subscribed =
            response['response'].notification_setting.is_subscribed;
            this.user_type =
              response['response'].notification_setting.user_type;
          
       
          if (response['response'].notification_setting.new_video === 1) {
            this.selected = true;
            this.value = response['response'].notification_setting.new_video;
          } else {
            this.selected = false;
            this.value = response['response'].notification_setting.new_video;
          }
          if (response['response'].notification_setting.reply_comment === 1) {
            this.comment = true;
            this.commentValue =
              response['response'].notification_setting.reply_comment;
          } else {
            this.comment = false;
            this.commentValue =
              response['response'].notification_setting.reply_comment;
          }
          if (response['response'].notification_setting.auto_play === 1) {
            this.autoplayInput = true;
          } else {
            this.autoplayInput = false;
          }
        },
        (error) => {
          this.apiService.failureResponse(error);
        }
      );
  }
  autoplay() {
    this.apiService
      .callPostAPI(
        'v2/notification/settings',
        { auto_play: this.autoplayInput },
        environment.notificationApiUrl
      )
      .subscribe(
        (response) => {
          this.apiService.successResponse(response);
        },
        (error) => {
          this.apiService.failureResponse(error);
        }
      );
    localStorage.setItem('autoPlay', this.autoplayInput);
  }

  showRevokePopUp(customerDevice) {
    this.getRevokeDevice(customerDevice);
    this.revokeDeviceDetails = customerDevice;
  }

  getRevokeDevice(customerDevice) {
    const { device_id } = customerDevice;
    this.apiService
      .callPostAPI(
        'v2/auth/get_revoke_device',
        { requested_device_id: device_id },
        environment.userApiUrl
      )
      .subscribe(
        (data: any) => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.revokeDeviceDetails.message = '';
          }
        },
        (error) => {}
      );
  }

  requestRevokeDevice() {
    const { device_id } = this.revokeDeviceDetails;
    const message = this.revokeDeviceDetails.message;

    if (message.length > 1000) {
      if (!this.revokeDeviceMsgError) {
        this.revokeDeviceMsgError = true;
      }

      setTimeout(() => {
        if (this.revokeDeviceMsgError) {
          this.revokeDeviceMsgError = false;
        }
      }, 5000);
      return;
    }

    this.apiService
      .callPostAPI(
        'v2/auth/revoke_device',
        { requested_device_id: device_id, message },
        environment.userApiUrl
      )
      .subscribe(
        (data: any) => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.getCustomerDeviceList();
            this.revokeDeviceDetails = null;
            this.apiService.successResponse(data);
          }
        },
        (error) => {
          this.revokeDeviceDetails = null;
        }
      );
  }

  closeRevokePopUp($event) {
    if (
      $event.target.classList.value === 'revoke-popup-overlay' ||
      $event.target.tagName === 'svg'
    ) {
      this.revokeDeviceDetails = null;
    }
  }

  cancelMySubscription() {
    this.translate.get('SETTINGS.YES').subscribe((res) => {
      this.yes = res + '...';
    });
    this.apiService
      .callDeleteAPI('v2/unsubscription', environment.paymentApiUrl)
      .subscribe(
        (data) => {
          this.translate.get('SETTINGS.YES').subscribe((res) => {
            this.yes = res;
          });
          const userValues = localStorage.getItem('currentUser');

       
          if (userValues) {

           
            const userData = this.authService.decryption(userValues);
            userData['response'].is_subscribed = 0;
            const userNewData = this.authService.encryption(userData);
            localStorage.setItem('currentUser', userNewData);
          }
          const elements = document.getElementsByClassName('cancel-subs-popup');
          elements[0].classList.remove('open');
          const element = document.getElementsByTagName('body');
          element[0].classList.remove('modal-popup');
          this.is_subscribed = 0;
          this.apiService.successResponse(data);
        },
        (error) => {
          const elements = document.getElementsByClassName('cancel-subs-popup');
          elements[0].classList.remove('open');
          const element = document.getElementsByTagName('body');
          element[0].classList.remove('modal-popup');
          this.toasterService.error('', 'Sorry! Could not cancel subscription. Try again.');
          this.translate.get('SETTINGS.YES').subscribe((res) => {
            this.yes = res;
          });
          this.apiService.failureResponse(error);
        }
      );
  }
  changeLanguage() {
    localStorage.setItem('currentLanguage', this.language);
    this.translate.use(this.language);
  }
}
