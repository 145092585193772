/**
 * Method to handle the favourite icon when the icon is clicked.
 * @param any event
 * @param array video
 */
const UpdateFavouriteIcon = (event: any, videos) => {
    const videoIndex = videos.findIndex((data) => {
        return data.slug === event.slug;
      });
      videos[videoIndex].is_favourite = (event.is_favourite) ? 0 : 1;
};
export {UpdateFavouriteIcon};
