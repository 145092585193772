import { Component, OnInit, Input } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../core/auth/auth.service';
import {UserService} from '../core/services/user.service';
import {UtilityService} from '../core/services/utility.service';
import disableScroll from 'disable-scroll';
import {TranslateService} from '@ngx-translate/core';
import {ApiService} from '../core/services/api.service';
import {environment} from '../../environments/environment';
import {AsYouType, parsePhoneNumber, parsePhoneNumberFromString} from 'libphonenumber-js';

@Component({
    selector: 'app-phone-login',
    templateUrl: './phone-login.component.html',
    styleUrls: ['./phone-login.component.scss']
})
export class PhoneLoginComponent implements OnInit {

    user: any = {};
    submitted = false;
    usernameMaxLength: any = 12;
    pwdMinLength: any = 6;
    pwdMaxlength: any = 60;
    LoggedIn = localStorage.getItem('currentUser');
    error: any = {};
    buttonText: any;
    commonError: string;
    show: Boolean = false;
    otp: Boolean = false;
    showPassword: Boolean = false;
  formattedPhoneNumber;
  signIn = false;

  //@Input()
  isVisible: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private toasterService: ToastrService,
        private userService: UserService,
        private utilityService: UtilityService,
        private translate: TranslateService,
        private apiService: ApiService,
    ) {
        this.translate.get('SIGN_IN.SIGN_IN').subscribe(res => {
            this.buttonText = res;
        });
    }

    ngOnInit() {
        if (this.LoggedIn) {
            this.router.navigate(['home']);
        }
    }

    login() {
        this.submitted = true;

        this.user.provider = 'NORMAL';
        this.authService.login(this.user)
            .subscribe(
                data => {
                    if (data['statusCode'] === 200 && data['error'] === false) {
                        disableScroll.off();
                        this.userService.displayPopup('close');
                        if (data['response'].name) {
                            const userData = this.authService.encryption(data);
                            localStorage.setItem('currentUser', userData);
                            localStorage.setItem('userName', data['response'].name);
                            localStorage.setItem('userEmail', data['response'].email);
                            localStorage.setItem('screenImage', data['response'].screenImage);
                        }
                        this.translate.get('SIGN_IN.SIGN_IN').subscribe(res => {
                            this.buttonText = res;
                        });

                        this.userService.nextPage.subscribe(
                            nextUrl => {


                                localStorage.setItem('nextUrl', nextUrl);  //   Setting url slug so i use to manage-screen

                                if (!localStorage.getItem('myUrl')) {
                                    if (nextUrl.indexOf('video') !== -1) {
                                        window.location.reload();
                                    } else {
                                        if (nextUrl === '/' || nextUrl === '/home' || !localStorage.getItem('screen-id')) {

                                            this.router.navigate(['/manage-profiles']);

                                        } else if (data['response'].is_subscribed === 1 && localStorage.getItem('subscribeUrl')) {

                                            this.router.navigate([localStorage.getItem('subscribeUrl').replace('video/', 'watch/')]);
                                        } else {

                                            this.router.navigate([nextUrl]);
                                        }
                                    }
                                } else if (localStorage.getItem('myUrl')) {
                                    if (data['response'].is_subscribed === 0) {
                                        localStorage.setItem('subscribeUrl', window.location.pathname);
                                        this.router.navigate(['subscription']);
                                        localStorage.removeItem('myUrl');
                                    } else if (data['response'].is_subscribed === 1) {
                                        this.router.navigate([window.location.pathname.replace('video/', 'watch/')]);
                                        localStorage.removeItem('myUrl');
                                    }
                                }
                            });
                    }
                },
                error => {
                    this.submitted = false;
                    this.translate.get('SIGN_IN.SIGN_IN').subscribe(res => {
                        this.buttonText = res;
                    });
                    if (error.error.statusCode === 422) {
                        if (typeof error.error.message === 'string' || error.error.message instanceof String) {
                            this.commonError = (error.error.message) ? error.error.message : error.message;
                            this.utilityService.VplyToaster();
                        } else {
                            this.error = error.error.message;
                        }
                    } else {
                        this.error = {};
                        this.toasterService.error('', (error.error.message) ? error.error.message : error.message);
                    }
                }
            );
    }


  

  toOtp() {
   

    this.user.provider = 'NORMAL';
  
        this.apiService.callPostAPI('v2/auth/send/otp', {
            phone_number: this.user.phone,
        }, environment.userApiUrl)
            .subscribe(
                data => {
                    if (data['statusCode'] === 200 && data['error'] === false) {
                        this.submitted = true;
                        this.otp = true;

                    } else {

                        this.submitted = false;
                    }
                },
                error => {
                    this.submitted = false;
                    this.toasterService.error('', 'Could not send OTP to mobile number');
                });


    }


    onPhoneNumberChange(phoneNumber: string) {
        // Parse the phone number
        const parsedPhoneNumber = parsePhoneNumber(phoneNumber, 'GH'); // Change 'US' to the appropriate country code

        const countryCodeMatch = /^\+(\d+)/.exec(phoneNumber);
        if (countryCodeMatch) {
            const countryCode = countryCodeMatch[1];
            const phoneNumberDigits = phoneNumber.slice(countryCodeMatch[0].length);

            // Parse the phone number with country code
            const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumberDigits, 'GH');

            if (parsedPhoneNumber) {


                // Format the parsed phone number
                const asYouType = new AsYouType(parsedPhoneNumber.country);
                const formattedNumber = asYouType.input(phoneNumber);

                const countryCode = '+' + parsedPhoneNumber.countryCallingCode;
                this.formattedPhoneNumber = `${countryCode}${formattedNumber.replace(/\s/g, '')}`;

            } else {
                // If the phone number is invalid, set formattedPhoneNumber to empty
                this.formattedPhoneNumber = '';
            }


        }

    }

    toPassword() {


        this.user.provider = 'NORMAL';

        this.apiService.callPostAPI('v2/auth/verify/otp', {
            phone_number: this.user.phone,
            otp: this.user.otp
        }, environment.userApiUrl)
            .subscribe(
                data => {
                    if (data['statusCode'] === 200 && data['error'] === false) {
                        this.submitted = true;
                        this.otp = false;
                        this.showPassword = true;

                    } else {
                        this.submitted = false;
                    }
                },
                error => {
                    this.submitted = false;
                    this.toasterService.error('', 'OTP could not be verified. Kindly try again');
                });
    }


  
  submitPassword() {
   
  
    this.submitted = true;
    this.isVisible = true
    this.signIn = true;

      console.log("Phone:::", this.user.phone)
      console.log("Password:::", this.user.password)

        this.apiService.callPostAPI('v2/auth/register', {
            login_type: 'normal',
            acesstype: 'web',
            phone: this.user.phone,
            password: this.user.password,
            password_confirmation: this.user.confirm_password,
            name: 'user'

        }, environment.userApiUrl)
            .subscribe(
                data => {
                    console.log("What is in Data:::", data)
                    if (data['statusCode'] === 200 && data['error'] === false) {
                      this.submitted = true;
          
                      this.isVisible = true
                      this.signIn = true;
                        //this.router.navigate(['home']);

                    } else {
                        this.submitted = false;
                    }
                },
                error => {
                    console.log("What is in Error:::", error)
                    this.submitted = false;
                    this.toasterService.error('', 'Kindly login if you\'re already registered. Account could not be created.');
                });
  }
  


    onChange(e) {
        this.error[e.target.name] = '';
    }

  
  onClose() {

   
    this.isVisible = true

        disableScroll.off();
        if (this.route.snapshot['_routerState'].url === '/sign-in') {
            this.router.navigate(['home']);
        }
        this.userService.displayPopup('close');
    }

    onClick(value: string) {
        if (value === 'forget') {
            this.userService.displayPopup('forget');
        } else {
            this.userService.displayPopup('sign-up');
        }
    }

    VplyToasterHide() {
        this.utilityService.VplyToasterHide();
    }

    toggleShowPassword() {
        this.show = !this.show;
    }
}

