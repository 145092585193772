import { Component, ElementRef, OnInit } from '@angular/core';
import { AuthService } from '../../../core/auth/auth.service';
import { ApiService } from '../../../core/services/api.service';
import { environment } from '../../../../environments/environment';
import { NgForm } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'app-manage-screen',
  templateUrl: './manage-screen.component.html',
  styleUrls: ['./manage-screen.component.scss']
})
export class ManageScreenComponent implements OnInit {
  login_type: Boolean = true;
  routeSub: any;
  showRemovebtn: any;
  userData: any;
  screens: any = {};
  screen: any = {};
  profileScreen: any = {};
  error: any = {};
  isAdd: any;
  screenDetail: any = {};
  firstName: string;
  showHideScreenForm: Boolean = false;
  loading = false;
  submitted = false;
  parentRequest: any;
  localUser: any;
  touched: Boolean = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  image: any;
  file: any;
  imageWidth: any;
  imageHeight: any;
  showCropper = false;
  currentDevice: any;
  remove_id: any;
  currentscreenId: any;
  isMainUser: boolean = false;
  constructor(
    private authService: AuthService, 
    private router: Router, 
    private apiService: ApiService,
    private toasterService: ToastrService
  ) {

   
  
    this.currentDevice = localStorage.getItem('device-id');
    this.userData = this.authService.decryption(
      localStorage.getItem('currentUser')
    );
    this.getScreens();


    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     console.log('Navigation successful:', event.url);
    //   }
    // });
  }
  @ViewChild('myInput')
  myInputVariable: ElementRef;

  ngOnInit() {
   
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.currentscreenId = localStorage.getItem('device-id');
    this.getProfile();


    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     console.log('Navigation successful:', event.url);
    //   }
    // });


  }
  getProfile() {
   
    this.localUser = this.authService.decryption();
    this.apiService.callGetAPI('v2/profile', environment.userApiUrl).subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.profileScreen = data['response'].screen;
          localStorage.setItem('currentUserEmail', data['response'].profile.email);
          localStorage.setItem('currentUserName', data['response'].profile.name);
        }
      },
      (error) => {
        this.apiService.failureResponse(error, true);
      }
    );
  }
  getScreens() {
    this.apiService.callGetAPI('v2/screens', environment.userApiUrl).subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.screens = data['response']['screens'];
          this.screens.forEach(element => {
            if(this.screens.length == 1){
              if(!element.hasOwnProperty('device_id')){
                this.parentRequest = 0;
              }else{
                this.parentRequest = 1;
              }
            }else{
              this.parentRequest = 1;
            }
            const dev = localStorage.getItem('device-id');
            if(element.device_id.toString() == dev.toString()){
                this.isMainUser = true;
            }
            if(element.is_default == 1){
                this.showRemovebtn = 1;
            }else{
              if(element.is_default == 0 && element.is_occupied == 1){
                this.showRemovebtn = 0;
              }else{
                this.showRemovebtn = 1;
              }
            }
          });
          window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }
      },
      (error) => {
        this.apiService.failureResponse(error, true);
      }
    );
  }

  addNewScreen() {
    this.screen = {};
    this.showHideScreenForm = true;
    this.isAdd = true;
  }
  editScreen(screen_id: any) {
    this.apiService.callGetAPI('v2/screens/' + screen_id, environment.userApiUrl).subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.isAdd = false;
          this.screenDetail = data['response']['screens'];
          this.screen.name = this.screenDetail[0].name;
          this.screen.id = this.screenDetail[0]._id;
          this.screen.is_default = this.screenDetail[0].is_default;
          this.screen.profile_image = this.screenDetail[0].profile_image;
        }
      },
      (error) => {
        this.toasterService.error('', error.error.message);
      }
    );
    this.showHideScreenForm = true;
  }

  screenRequest(screen_id: any) {
    this.apiService.callPostAPI('v2/screen/request/'+ screen_id, '',environment.userApiUrl).subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          //this.toasterService.success('', data['message']);
          this.toasterService.success('', data['message'], {
            timeOut: 15000,
          });
        }
      },
      (error) => {
        this.toasterService.error('', error.error.message);
      }
    );
  }

  cancelForm(){
    this.showHideScreenForm = false;
    this.touched = false;
    this.getScreens();
  }

  saveScreen(form: NgForm) {
    this.touched = true;
    if (form.valid) {
      this.submitted = true;
      this.apiService
        .callPostAPI(
          'v2/save-screen',
          {
            screen: this.screen.id,
            name: this.screen.name,
            profile_image: this.screen.profile_image
          },
          environment.userApiUrl
        )
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              this.getScreens();
              this.touched = false;
              this.submitted = false;
              this.showHideScreenForm = false;
              if(this.userData['response'].screen === this.screen.id){
                localStorage.setItem('screenImage', this.screen.profile_image);
              }
              this.toasterService.success('', data['message']);
            }
          },
          (error) => {
            this.touched = false;
            this.submitted = false;
            this.toasterService.error('', error.error.message);
          }
        );
    } else {
      this.submitted = false;
    }
  }
  removeScreen(screen_id: any){
    const elements = document.getElementsByClassName('cancel-subs-popup');
    elements[0].classList.add('open');
    const element = document.getElementsByTagName('body');
    element[0].classList.add('modal-popup');
    this.remove_id = screen_id;
  }

  cancelremove() {
    const elements = document.getElementsByClassName('cancel-subs-popup');
    elements[0].classList.remove('open');
    const element = document.getElementsByTagName('body');
    element[0].classList.remove('modal-popup');
    this.remove_id = '';
  }

  remove(){
    this.apiService.callDeleteAPI('v2/remove-screen/' + this.remove_id, environment.userApiUrl)
    .subscribe(
    data => {
      this.cancelremove();
      this.getScreens();
      this.touched = false;
      this.submitted = false;
      this.showHideScreenForm = false;
      this.toasterService.success('', 'Profile removed successfully.');
    },
    error => {
      this.toasterService.error('', error.error.message);
    });
  }

  setScreen(screen: any){
    const screen_id = screen._id; 
    this.apiService.callGetAPI('v2/set-screen/' + screen_id, environment.userApiUrl).subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.userData['response'].screen = screen_id;
          const userNewData = this.authService.encryption(this.userData);
          localStorage.setItem('currentUser', userNewData);
          localStorage.setItem('screenImage', screen.profile_image);
          localStorage.setItem('is_default_profile', screen.is_default);
          localStorage.setItem('screen-id', screen_id);
          // window.location.href = '/home';
          // this.toasterService.success('', data['message']);

         
          // console.log("NEXT URL::", localStorage.getItem('nextUrl'))

          if (data['response'].is_subscribed === 0 && !localStorage.getItem('nextUrl')) {
            window.location.href = '/subscription';
          }

          else if (data['response'].is_subscribed === 0 &&  localStorage.getItem('nextUrl')){
            this.router.navigate([localStorage.getItem('nextUrl')]);
          }
          
          else {
            if (localStorage.getItem('subscribeUrl')) {
              window.location.href = localStorage.getItem('subscribeUrl').replace('video/', 'watch/');
            } else {
              window.location.href = '/home';
            }
          }
          this.toasterService.success('', data['response'].message);
        }
      },
      (error) => {
        this.toasterService.error('', error.error.message);
      }
    );
  }

  fileChangeEvent(event: any): void {
    const fileNameArray = event.srcElement.files[0].name.split('.');
    const fileExt = fileNameArray[fileNameArray.length - 1];
    if (
      fileExt === 'jpg' ||
      fileExt === 'jpeg' ||
      fileExt === 'png' ||
      fileExt === 'gif'
    ) {
      document.getElementsByTagName('body')[0].classList.add('modal-popup');
      document.getElementsByTagName('body')[0].classList.add('crop-overlay');
      this.showCropper = true;
      this.imageChangedEvent = event;
    } else {
      this.toasterService.error('', 'Only PNG,GIF,JPG files allowed.');
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    this.showCropper = true;
  }
  loadImageFailed() {
    this.showCropper = false;
  }

  onCancel() {
    document.getElementsByTagName('body')[0].classList.remove('modal-popup');
    document.getElementsByTagName('body')[0].classList.remove('crop-overlay');
    this.showCropper = false;
    this.imageChangedEvent = '';
    this.myInputVariable.nativeElement.value = '';
  }

  onImageCropped() {
    const image = this.b64toBlob(this.croppedImage);
    this.setProfileImage(image, this.userData);
    this.showCropper = false;
    this.imageChangedEvent = '';
  }

  b64toBlob(b64Data) {
    const image = b64Data.split(',');
    const contentType = 'image/png';
    const sliceSize = 512;
    const byteCharacters = atob(image[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  setProfileImage(event, user) {
    document.getElementsByTagName('body')[0].classList.remove('modal-popup');
    document.getElementsByTagName('body')[0].classList.remove('crop-overlay');
    // this.screen.profile_image = 'images/hmu7rn9o269-2695814-hotstar-tv-movies-hotstar-app-icon-1644251318.png';
    // return; 
    this.loading = true;
    const form = new FormData();
    form.append('profile_picture', event);
    this.apiService
      .callPostAPI('v2/screen_image', form, environment.userApiUrl)
      .subscribe(
        (data) => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.screen.profile_image = data['response'];
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }
}
