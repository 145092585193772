import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../core/services/api.service';
import { environment } from '../../environments/environment';
import { Location } from '@angular/common';



@Component({
  selector: 'app-vcallback',
  templateUrl: './vcallback.component.html',
  styleUrls: ['./vcallback.component.scss']
})
export class VcallbackComponent implements OnInit {
  action: string;
  transactionId: any;
  msisdn: any;
  serviceid: any;
    subscriptionId: any;
    status: any;
    spinner: any = false;


  constructor(private route: ActivatedRoute, private router: Router, private apiService: ApiService, public location: Location) { }

    ngOnInit(): void {
        this.spinner = true;
        // Access the entire URL including the encoded string
        const encodedUrl = decodeURIComponent(this.route.snapshot.url.join('/'));

        // Decode the encoded string twice
        const decodedUrl = decodeURIComponent(decodeURIComponent(encodedUrl));

        console.log(decodedUrl);

        const paramsMap = new Map<string, string>();
        for (const param of decodedUrl.split('&')) {
            const [key, value] = param.split('=');
            paramsMap.set(key, value);
        }

        this.transactionId = paramsMap.get('transactionId');
        this.msisdn = paramsMap.get('msisdn');
        this.status = paramsMap.get('status');
        this.serviceid = paramsMap.get('serviceid');
        this.subscriptionId = paramsMap.get('subscriptionId');


        if (!!this.msisdn && this.status === 'ACTIVE') {
            this.runOffsiteBilling(this.subscriptionId, this.msisdn);
        } else {
            this.router.navigate(['/subscription/payment-info/failure']);
        }
    }

    runOffsiteBilling(transactionId, msisdn) {
        this.spinner = false;
        this.apiService.callPostAPI('v2/airtime-offsite-billing', {
            'transactionId': transactionId,
            'planId':  this.serviceid === '1001' ? 'month' : 'gh-daily',
            'msisdn': msisdn,
            'carrier': 'VODGH'
        }, environment.paymentApiUrl)
            .subscribe(
                data => {
                    if (data['statusCode'] === 200 && data['error'] === false) {
                        localStorage.setItem('transaction_id', transactionId);
                        this.router.navigate(['/subscription/payment-info/success']);
                    }
                },
                error => {
                    this.router.navigate(['/subscription/payment-info/failure']);
                }
            );
    }

    retry() {
        this.router.navigate(['/']);
    }

}
