import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot, UrlSegment } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { WatchVideoComponent } from './modules/watch-video/watch-video.component';
import { VideoDetailComponent } from './modules/video-detail/video-detail.component';


import { EmbedComponent } from './modules/embed/embed.component';
import { WebseriesDetailComponent } from './modules/category/webseries-detail/webseries-detail.component';
import { TrailerComponent } from './modules/video-detail/trailer/trailer.component';
import { SeasonComponent } from './modules/series-detail/season/season.component';
import { EpisodesComponent } from './modules/series-detail/season/episodes/episodes.component';
import { ManageScreenComponent } from './modules/profile/manage-screen/manage-screen.component';
import { MyAccountComponent } from './modules/profile/my-account/my-account.component';
import { FavoriteVideosComponent } from './modules/profile/favorite-videos/favorite-videos.component';
import { ChangePasswordComponent } from './modules/profile/change-password/change-password.component';
import { HistoryComponent } from './modules/profile/history/history.component';
import { NotificationsComponent } from './modules/profile/notifications/notifications.component';
import { SavePlaylistComponent } from './modules/profile/save-playlist/save-playlist.component';
import { MyPlaylistComponent } from './modules/profile/my-playlist/my-playlist.component';
import { TransactionComponent } from './modules/profile/transaction/transaction.component';
import { PurchasedVideosComponent } from './modules/profile/purchased-videos/purchased-videos.component';
import { SettingsComponent } from './modules/profile/settings/settings.component';
import { SavedCardComponent } from './modules/profile/saved-card/saved-card.component';

import { ProfileComponent } from './modules/profile/profile.component';
import { VcallbackComponent } from './vcallback/vcallback.component';



// Export a function that returns tje routing configuration
export function vodafoneCallbackMatcher(url: UrlSegment[]) {
  const regex = /^vodafone_callback(.+)$/;
  const match = url[0].path.match(regex);

  if (match) {
    return { consumed: url, posParams: { appendedString: new UrlSegment(match[1], {}) } };
  } else {
    return null;
  }
}







export function marketingMatcher(url: UrlSegment[]): { consumed: UrlSegment[], posParams: any } | null {
  // const regex = /^marketing=(true)$/;
  const regex = /(\%3F|\?)marketing=true\b/


  // Check if the URL segment matches the desired format
  const match = url[0].path.match(regex);

  if (match) {
    return { consumed: url, posParams: { marketing: new UrlSegment(match[1], {}) } };
  } else {
    return null;
  }
}



const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'act/:token',
    component: HomeComponent,
  },
  {
    path: 'permit/:permitcode',
    component: HomeComponent,
  },
  {
    path: 'watch/:video_id',
    component: WatchVideoComponent,
  },
  {
    path: 'embed/:video_id',
    component: EmbedComponent,
  },
  {
    path: 'watch/:video_id/:playlist_id/:name',
    component: WatchVideoComponent,
  },
  {
    path: 'series/:slug',
    component: WebseriesDetailComponent,
  },

  // {
  //   path: 'video/:slug',
  //   component: VideoDetailComponent,
  
  // },
  {
    path: 'profile',
   //loadChildren: './modules/profile/profile.module#ProfileModule',
   component: ProfileComponent,
    
  },
  {
    path: 'manage-profiles',
    component: ManageScreenComponent,

  },

  {
    path: 'my-account',
    component: MyAccountComponent,

  },
  {
    path: 'favorite-videos',
    component: FavoriteVideosComponent,

  },

  {
    path: 'change-password',
    component: ChangePasswordComponent,

  },

  {
    path: 'history',
    component: HistoryComponent,
  },
  {
    path: 'notifications',
    component: NotificationsComponent,
  },
  {
    path: 'save-playlist',
    component: SavePlaylistComponent,
  },
  {
    path: 'my-playlist',
    component: MyPlaylistComponent,
  },
  {
    path: 'transaction',
    component: TransactionComponent,
  },
  {
    path: 'rented-videos',
    component: PurchasedVideosComponent,
  },
  {
    path: 'settings',
    component: SettingsComponent,
  },
  {
    path: 'saved-card',
    component: SavedCardComponent,
  }

  ,
  {
    path: 'vodafone_callback',
    component: VcallbackComponent,
    pathMatch: 'full',
    
  }
  ,

  {
    matcher: vodafoneCallbackMatcher,
    component: VcallbackComponent,
  },

  {
    matcher: marketingMatcher,
    component: HomeComponent,
  },

  {
    path: 'marketing',
    component: HomeComponent,
    pathMatch: 'full',

  }
  ,
  


  {
    path: 'playlist',
    loadChildren: './modules/playlist/playlist.module#PlaylistModule',
  },
  {
    path: 'category',
    loadChildren: './modules/category/category.module#CategoryModule',
  },
  {
    path: 'genre',
    loadChildren: '../app/modules/genre/genre.module#GenreModule',
  },
  {
    path: 'video/:slug',
    loadChildren:
      './modules/video-detail/video-detail.module#VideoDetailModule',
  },


  {
    path: 'webseries/:seriesslug',
    loadChildren:
      './modules/series-detail/series-detail.module#SeriesDetailModule',
  },
  {
    path: 'video/:slug/:playlist',
    loadChildren:
      './modules/video-detail/video-detail.module#VideoDetailModule',
  },
  {
    path: 'content/:slug',
    loadChildren: './modules/cms/cms.module#CmsModule',
  },
  {
    path: 'contact-us',
    loadChildren: './modules/contact-us/contact-us.module#ContactUsModule',
  },
  {
    path: 'new-videos',
    loadChildren: './modules/search-core/search-core.module#SearchCoreModule',
  },
  {
    path: 'trending-videos',
    loadChildren: './modules/search-core/search-core.module#SearchCoreModule',
  },
  {
    path: 'search',
    loadChildren: './modules/search-core/search-core.module#SearchCoreModule',
  },
  {
    path: 'reset-password/:id',
    loadChildren:
      './modules/reset-password/reset-password.module#ResetPasswordModule',
  },
  {
    path: 'save-playlist',
    loadChildren:
      './modules/profile/save-playlist/save-playlist.module#SavePlaylistModule',
  },
  {
    path: 'subscription',
    loadChildren:
      './modules/subscription/subscription.module#SubscriptionModule',
  },
  {
    path: 'onboarding-signup',
    loadChildren:
      './modules/onboarding-signup/onboarding-signup.module#OnboardingSignupModule',
  },
  {
    path: 'live',
    loadChildren: './modules/live-vidoes/live-vidoes.module#LiveVidoesModule',
  },
  {
    path: 'card-payment/:plan',
    loadChildren:
      './modules/card-payment/card-payment.module#CardPaymentModule',
  },
  {
    path: 'video-not-found',
    loadChildren:
      './modules/video-not-found/video-not-found.module#VideoNotFoundModule',
  },
  {
    path: 'checkout/:type/:slug',
    loadChildren: './modules/checkout/checkout.module#CheckoutModule',
  },
  {
    path: 'checkout/:type/:seriesid/:slug',
    loadChildren: './modules/checkout/checkout.module#CheckoutModule',
  },
  {
    path: 'offers',
    loadChildren:
      './modules/coupon-offers/coupon-offers.module#CouponOffersModule',
  },
  {
    path: 'watch-trailer/:video_id',
    component: TrailerComponent,
  },
  {
    path: 'season/:series_slug/:season_id',
    component: SeasonComponent,
  },
  {
    path: 'episode/:episode-id',
    component: EpisodesComponent,
  },
  {
    path: '**',
    loadChildren:
      './modules/page-not-found/page-not-found.module#PageNotFoundModule',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
