import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { AuthService } from '../../../core/auth/auth.service';
import disableScroll from 'disable-scroll';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, OnDestroy {
  videos: any = [];
  final = false;
  flag = false;
  loading = false;
  page = 0;
  notFound = false;
  noVideo;
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private translate: TranslateService
  ) {
    this.translate.get('WATCH_HISTORY.NO_VIDEOS').subscribe((res) => {
      this.noVideo = res;
    });
  }

  ngOnInit() {
    this.getHistory();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (
      $(window).scrollTop() + $(window).height() > $('footer').offset().top &&
      this.final === false &&
      this.flag === false
    ) {
      this.flag = true;
      this.getHistory();
    }
  }

  getHistory() {
    this.loading = true;
    this.page = this.page + 1;
    const userData = this.authService.decryption(
      localStorage.getItem('currentUser')
    );

    this.apiService
      .callGetAPI('v2/home_more?type=recent&page=' + this.page)
      .subscribe(
        (data) => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.videos = this.videos.concat(data['response'].data);
            this.final = data['response'].next_page_url === null ? true : false;
            this.loading = false;
            this.flag = false;
            this.notFound = this.videos.length > 0 ? false : true;
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }

  removeHistory(event = null) {
    if (event) {
      const videoIndex = this.videos.findIndex((data) => {
        return data.slug === event.slug;
      });
      this.videos.splice(videoIndex, 1);
      this.notFound = this.videos.length > 0 ? false : true;
    } else {
      const userData = this.authService.decryption(
        localStorage.getItem('currentUser')
      );
      const screen = userData['response'] !== undefined ? userData['response'].screen : '';
      this.apiService.callPostAPI('v2/clear_recent_view', {screen: screen}).subscribe(
        (data) => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.videos = [];
            this.notFound = this.videos.length > 0 ? false : true;
            this.apiService.successResponse(data, true);
            disableScroll.off();
          }
        },
        (error) => {
          disableScroll.off();
          this.apiService.failureResponse(error, true);
        }
      );
    }
  }

  ngOnDestroy(): void {
    disableScroll.off();
  }
}
