import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { AuthService } from '../../../core/auth/auth.service';
import disableScroll from 'disable-scroll';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import {UpdateFavouriteIcon} from '../../../shared/utils/updatefavouriteicon';
declare var $: any;
@Component({
  selector: 'app-favorite-videos',
  templateUrl: './favorite-videos.component.html',
  styleUrls: ['./favorite-videos.component.scss']
})
export class FavoriteVideosComponent implements OnInit, OnDestroy {
  videos: any = [];
  final = true;
  flag = true;
  loading = false;
  page = 0;
  notFound = false;
  noVideo: any;
  userData: any;
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private translate: TranslateService
  ) {
    if(localStorage.getItem('currentUser')){
      this.userData = this.authService.decryption(
        localStorage.getItem('currentUser')
      );
    }
    this.translate.get('MY_FAVOURITE.NO_VIDEOS').subscribe((res) => {
      this.noVideo = res;
    });
  }

  ngOnInit() {
    this.getFavorite();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (
      $(window).scrollTop() + $(window).height() > $('footer').offset().top &&
      this.final === false &&
      this.flag === false
    ) {
      this.flag = true;
      this.getFavorite();
    }
  }

  getFavorite() {
    this.loading = true;
    this.page = this.page + 1;
    this.apiService
      .callGetAPI(
        'v2/favourite?page=' + this.page,
        environment.userActionApiUrl
      )
      .subscribe(
        (data) => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.videos = this.videos.concat(data['response'].data);
            this.final = data['response'].next_page_url === null ? true : false;
            this.loading = false;
            this.flag = false;
            this.notFound = this.videos.length > 0 ? false : true;
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }
  /**
   * Method to handle the favourite icon when the icon is clicked.
   * @param event
   * @param array video
   */
  updateFavourite(event: any) {
    const videoIndex = this.videos.findIndex((data) => {
      return data.slug === event.slug;
    });
    this.videos.splice(videoIndex, 1);
    this.notFound = this.videos.length > 0 ? false : true;
  }

  ngOnDestroy() {
    disableScroll.off();
  }
}
