import { Component, OnInit, HostListener } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { environment } from '../../../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  notifications: any = [];
  page = 0;
  final = false;
  flag = false;
  loading = false;
  filter = 'all';
  format = 'MMM dd y hh:mm a';
  notFound = false;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if ($(window).scrollTop() + $(window).height() > $('footer').offset().top && this.final === false && this.flag === false) {
      this.flag = true;
      this.getNotifications();
    }
  }

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.getNotifications();
  }

  getNotifications() {
    this.loading = true;
    this.page = this.page + 1;
    this.apiService.callGetAPI('v2/notifications?page=' + this.page, environment.notificationApiUrl)
      .subscribe(
        data => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.notifications = this.notifications.concat(data['response'].data);
            this.final = (data['response'].next_page_url === null) ? true : false;
            this.loading = false;
            this.flag = false;
            this.notFound = (this.notifications.length > 0) ? false : true;
          }
        },
        error => {
          this.apiService.failureResponse(error, true);
        });
  }

}
