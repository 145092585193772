import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss']
})

export class CouponComponent implements OnInit {
  @Input() receivedAmount: any;
  @Output() messageToEmit = new EventEmitter<any>();
  calculation: any;
  submitted = true;
  isRemove = false;
  isHidden = false;
  offer: any;
  errorMessage: any;
  successMessage: any;
  amount: any;
  tempAmount: any;
  error: any = {};
  code: any;
  disableTextbox = false;
  coupon: Object = {};
  constructor(private apiService: ApiService) {
  }
  ngOnInit() {
    this.tempAmount = this.receivedAmount;
    const couponResponse = localStorage.getItem('couponResponse');
    if (couponResponse && couponResponse !== '') {
      const code = JSON.parse(couponResponse);
      this.code = code['code'];
      this.couponCalculation(JSON.parse(couponResponse));
    }
  }
  /**
   * for handling the coupons
   */
handleCoupon() {
    this.isHidden = false;
    if (this.code !== undefined) {
      this.apiService.callPostAPI('v2/coupon/verify', { 'code': this.code },
        environment.paymentApiUrl).subscribe(res => {
          this.couponCalculation(res);
        },
          err => {
            this.successMessage = '';
            this.errorMessage = err['error']['message'];
            this.receivedAmount = this.tempAmount;
          });
    }
  }
  /**
   * for coupon calculations
   * @param res
   */
  couponCalculation(res) {
    this.offer = res['response'].offer;
    if (res['response']['offer_type'] === 'percentage') {
      this.calculation = this.receivedAmount * this.offer / 100;
      this.calculation = Math.round(this.calculation);
      this.receivedAmount = this.receivedAmount - this.calculation;
    } else {
      this.calculation = this.offer;
      this.receivedAmount = this.receivedAmount - this.offer;
    }
    this.coupon['amount'] = this.receivedAmount;
    this.coupon['code'] = this.code;
    this.messageToEmit.emit(this.coupon);
    this.isHidden = true;
    this.errorMessage = '';
    this.successMessage = res['message'];
    this.disableTextbox = true;
    res['code'] = this.code;
    localStorage.setItem('couponResponse', JSON.stringify(res));
  }
  removeSpace() {
    this.code = this.code.trim();
    if (this.code === '') {
      this.errorMessage = '';
      this.submitted = true;
    } else {
      this.submitted = false;
    }
  }
  clearValue() {
    this.submitted = true;
    this.isHidden = false;
    this.code = '';
    this.successMessage = '';
    this.errorMessage = '';
    this.receivedAmount = this.tempAmount;
    this.coupon['amount'] = this.receivedAmount;
    this.messageToEmit.emit(this.coupon);
    this.disableTextbox = false;
    localStorage.removeItem('couponResponse');
  }
  checkInvalid() {
    if (this.code) {
      this.errorMessage = '';
    }
  }
}
