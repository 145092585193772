import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
declare var $: any;

@Component({
  selector: 'app-save-playlist',
  templateUrl: './save-playlist.component.html',
  styleUrls: ['./save-playlist.component.scss']
})
export class SavePlaylistComponent implements OnInit {

  playlists: any = [];
  title = 'Remove Playlist';
  page = 0;
  final = false;
  flag = false;
  loading = false;
  scrollOn = false;

  constructor(
    private apiService: ApiService
  ) {
    this.loading = true;
    this.page = 0;
    this.playlists = [];
    this.getPlaylists();
  }


  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if ($(window).scrollTop() + $(window).height() > $('footer').offset().top &&
      this.final === false && this.flag === false && this.scrollOn === true) {
      this.loading = true;
      this.flag = true;
      this.getPlaylists();
    }
  }
  ngOnInit() { }

  getPlaylists() {
    this.page = this.page + 1;
    this.apiService.callGetAPI('v2/playlists?page=' + this.page)
      .subscribe(
        data => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.playlists = this.playlists.concat(data['message'].data);
            this.final = (data['message'].next_page_url === null) ? true : false;
            this.loading = false;
            this.flag = false;
            this.scrollOn = true;
          }
        },
        error => {
          this.apiService.failureResponse(error, true);
        });
  }

  unfollowPlaylist(slug: any) {
    this.apiService.callDeleteAPI('v2/playlists?playlist_slug=' + slug)
      .subscribe(
        data => {
          const videoIndex = this.playlists.findIndex(element => {
            return element.slug === slug;
          });
          if (videoIndex !== -1) {
            this.playlists.splice(videoIndex, 1);
          }
          this.apiService.successResponse(data, '');
        },
        error => {
          this.apiService.failureResponse(error, true);
        });
  }

}


