/**
 *Method to add class to parent div when last item in a slider is hovered
 * @param any event
 * @param array video
 */
const HandleLastItemonHover = (event, i: number, section: string, sliderMovedData: number) => {
    if (i === sliderMovedData - 1) {
        const element = document.getElementsByClassName(section);
        if (element) {
          (event.type === 'mouseover') ? element[0].classList.add('item-hovered') : element[0].classList.remove('item-hovered');
        }
    }
};
export {HandleLastItemonHover};
