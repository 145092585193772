import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  constructor() { }
  // Observable User details
  private currentVideo = new BehaviorSubject<any>('');
  currVideo = this.currentVideo.asObservable();
  private videoPaymentDataVar = new BehaviorSubject<any>('');
  videoPaymentInfo = this.videoPaymentDataVar.asObservable();
  private setWatchFromBeginningFlag = new BehaviorSubject<boolean>(false);
  fetchWatchFromBeginningFlag = this.setWatchFromBeginningFlag.asObservable();
  private setVideoAnalyticsAPIStatus = new BehaviorSubject<boolean>(true);
  fetchVideoAnalyticsAPIStatus = this.setVideoAnalyticsAPIStatus.asObservable();

  // user details
  videoData(data: any) {
    this.currentVideo.next(data);
  }

  videoPaymentData(data: any) {
    this.videoPaymentDataVar.next(data);
  }

  setWatchFromBeginning(data: boolean) {
    this.setWatchFromBeginningFlag.next(data);
  }
  /**
   * Method to set the v2/videoanalytics endpoint response flag.
   *
   * @param boolean data (true/false)
   * @returns void
   *
   */
  setVideoAnalyticsResponseStatus(data: boolean) {
    this.setVideoAnalyticsAPIStatus.next(data);
  }
}
