import { Component, OnInit, ElementRef, AfterViewChecked, AfterViewInit, HostListener } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { UserService } from '../../core/services/user.service';
import { DatepickerOptions } from 'ng2-datepicker';
import { AuthService } from '../../core/auth/auth.service';
import { UtilityService } from '../../core/services/utility.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import disableScroll from 'disable-scroll';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { HttpClient  } from '@angular/common/http';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

declare var $: any;
export const APP_DATE_FORMATS = {
  parse: {
      dateInput: ['DD/MM/YYYY', 'MM/DD/YYYY', 'YYYY/MM/DD'],
  },
  display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
  },
};
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: DateAdapter, useClass: MomentDateAdapter},
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: APP_DATE_FORMATS }
]
})
export class ProfileComponent implements OnInit, AfterViewChecked {
  user: any = {};
  screen: any = {};
  userDetails: any = {};
  buttonText: any;
  loading = false;
  submitted = false;
  localUser: any;
  error: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper = false;
  date = new Date();
  minDate = new Date(1950, 0, 1); 
  //maxDate = new Date(Date.now());
  previous_date = this.date.setFullYear(this.date.getFullYear() - 10 ); // For subtract use minus (-)
  maxDate = new Date(this.previous_date);
  // options: DatepickerOptions = {
  //   minYear: 1950,
  //   displayFormat: 'DD-MM-YYYY',
  //   barTitleFormat: 'MMM, YYYY',
  //   placeholder: 'Date of Birth',
  //   useEmptyBarTitle: false,
  //   maxDate: new Date(Date.now()),
  // };
  success: any;
  initialCountry = 'in';
  inttelInputOptions: object = {
    initialCountry: this.initialCountry,
  };
  countries: object = {};
  datepick = false;
  inttelInputInstance;
  image: any;
  file: any;
  imageWidth: any;
  startAt: any;
  imageHeight: any;
  invaliddate: Boolean = false;
  subscriptionPlans: any;
  setWidth: Boolean = true;
  showHideProfileForm: Boolean = false;
  DEVICE_RESTRICTION_TYPE: string;
  planDeviceLimit: any;
  is_default_profile: any = 0;
  ipaddress = '';
  phoneError: Boolean = false;
  // @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
  //   //$(".cdk-overlay-backdrop.cdk-overlay-backdrop-showing").css('opacity', 0);
  //   disableScroll.off();
  // }
  
  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private utilityService: UtilityService,
    private toasterService: ToastrService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.translate.get('MY_PROFILE.UPDATE_PROFILE').subscribe((res) => {
      this.buttonText = res;
    });
    this.is_default_profile = Number(localStorage.getItem('is_default_profile'));
  }
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  

  ngOnInit() {
    this.getProfile();
    this.fetchPlans();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    window.addEventListener(
      'keyup',
      function (event) {
        if (event.keyCode === 27) {
          disableScroll.off();
        }
      }.bind(this)
    );
    this.startAt = new Date();
  }
  ngAfterViewChecked() {
    // tslint:disable-next-line:no-unused-expression
    let flagWidth = 0;
    let countryCode = 0;
    const flagElement = document.getElementsByClassName('flag-container')[0];
    const countryCodeElement = document.getElementsByClassName(
      'country-code'
    )[0];
    if (flagElement) {
      flagWidth = flagElement.clientWidth;
    }
    if (countryCodeElement) {
      countryCode = countryCodeElement.clientWidth;
    }
    $('#countryCode').css('padding-left', flagWidth + countryCode + 5 + 'px');
    this.setWidth = false;
  }

  getProfile() {
    this.localUser = this.authService.decryption();
    this.apiService.callGetAPI('v2/profile', environment.userApiUrl).subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.userDetails = data['response'];
          this.user = data['response'].profile;
          this.screen = data['response'].screen;
          this.image = this.user.profile_picture;
          const csimage = localStorage.getItem('screenImage');
          if(csimage !== '' && csimage !== 'undefined'){
            this.image = localStorage.getItem('screenImage');
          } else if(csimage != 'undefined' && this.user) {
            this.image = this.user.profile_picture;
          } else {
            this.image = 'assets/images/user-icon.png';
          }
          this.DEVICE_RESTRICTION_TYPE = data['response'].device_restriction_type;
          this.planDeviceLimit = (data['response'].subscribed_plan !== null) ? data['response'].subscribed_plan.device_limit : null;
          this.setLocalStorage(data['response']);
          if (this.user.dob) {
            const dateArray = this.user.dob.split('-');
            this.user.dob = new Date(
              dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0]
            );
          }
          this.user.dob = this.user.dob === null ? '' : this.user.dob;
          this.datepick = false;
          if (
            this.user.country_code === undefined ||
            this.user.country_code === '' ||
            this.user.country_code === null
          ) {
            this.user.country_code = 91;
          }
          if (this.inttelInputInstance) {
            this.inttelInputInstance.intlTelInput(
              'setCountry',
              this.user.iso ? this.user.iso : this.initialCountry
            );
          }
        }
      },
      (error) => {
        this.apiService.failureResponse(error, true);
      }
    );
  }
  showProfileForm() {
    this.showHideProfileForm = true;
  }
  hideProfileForm() {
    this.showHideProfileForm = false;
    this.getProfile();
  }
  editProfile() {

  
    const userPhone = "+" + this.user.country_code + this.user.phone

     console.log("Phone ----", userPhone)

    // if (/^\+233\d{9}/.test(userPhone)) {
    //   console.log("Phone Valid----")
    //   this.phoneError = false;
    // }   
    
    // else {
    //   this.phoneError = true;
    //   console.log("Phone InValid----")
    //   }
    
    this.invaliddate = false;
    if (this.datepick !== true) {
      const date = new Date(this.user.dob);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear(); 
      this.submitted = true;
      this.translate.get('MY_PROFILE.UPDATE_PROFILE').subscribe((res) => {
        this.buttonText = res + '...';
      });
  if (/^\+233\d{9}/.test(userPhone)) {

    console.log("Phone Valid----")

    this.phoneError = false;

        this.apiService
          .callPostAPI(
            'v2/customerProfile',
            {
              name: this.user.name,
              email: this.user.email,
              phone: userPhone,
              age: this.user.dob ? day + '-' + month + '-' + year : '',
              country_code: this.user.country_code,
              iso: this.user.iso,
            },
            environment.userApiUrl
          )
          .subscribe(
            (data) => {
              if (data['statusCode'] === 200 && data['error'] === false) {
                this.setLocalStorage(data['response']);
                this.translate
                  .get('MY_PROFILE.UPDATE_PROFILE')
                  .subscribe((res) => {
                    this.buttonText = res;
                  });
                this.submitted = false;
                this.success = data['message'];
                this.utilityService.VplyToaster();
                this.hideProfileForm();
              }
            },
            (error) => {
              this.translate.get('MY_PROFILE.UPDATE_PROFILE').subscribe((res) => {
                this.buttonText = res;
              });
              this.submitted = false;
              this.error = this.apiService.failureResponse(error);
            }
          );
        
      } else {

    console.log("Phone In Valid----")
  
        this.phoneError = true;
      }
    }
  }
  

  setLocalStorage(data) {
    this.localUser = this.authService.decryption();
    if (data.profile) {
      this.localUser['response'] = data.profile;
      // console.log(data.profile.email);
      localStorage.setItem('currentUserEmail', data.profile.email);
      localStorage.setItem('currentUserName', data['response'].profile.name);
      this.localUser.subscribed_plan = data.subscribed_plan;
      const userData = this.authService.encryption(this.localUser);
      this.userService.userData(userData);
    } else {
      this.localUser['response'] = data;
      const userData = this.authService.encryption(this.localUser);
      this.userService.userData(userData);
      localStorage.removeItem('currentUser');
      // console.log(userData);
      localStorage.setItem('currentUser', userData);
    }
  }

  onChange(e) {
    // if (e.target.name === '') {
    //   this.error = [];
    //   this.datepick = true;
    //   this.error[e.target.name] = '';
    // }
    this.invaliddate = false;
    if (this.user.dob !== undefined && this.user.dob !== '' && this.user.dob !== null) {
      if (this.user.dob.getTime() > new Date().getTime() - 315585697857) {
        this.datepick = true;
      } else {
        this.datepick = false;
      }
    }else{
      if(this.user.dob === '' || this.user.dob === null){
        this.invaliddate = true;
      }
      
    }
  }
  phoneKeyDown(e){
	 
		const keyCode = e.keyCode;  
		if (( (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) && e.keyCode !=8 && e.keyCode != 191 && e.keyCode != 37 && e.keyCode != 39) {
			e.preventDefault();
		} 	  
}

  goToSubscription() {
    this.router.navigate(['/subscription']);
  }
  telInputObject(obj) {
    this.inttelInputInstance = obj;
    this.countries = this.inttelInputInstance.intlTelInput.getCountryData();
  }

  onCountryChange(data) {
    
    this.user.country_code = data.dialCode;
    this.user.iso = data.iso2;
  }
  VplyToasterHide() {
    this.utilityService.VplyToasterHide();
  }
  fileChangeEvent(event: any): void {
    const fileNameArray = event.srcElement.files[0].name.split('.');
    const fileExt = fileNameArray[fileNameArray.length - 1];
    if (
      fileExt === 'jpg' ||
      fileExt === 'jpeg' ||
      fileExt === 'png' ||
      fileExt === 'gif'
    ) {
      document.getElementsByTagName('body')[0].classList.add('modal-popup');
      document.getElementsByTagName('body')[0].classList.add('crop-overlay');
      this.showCropper = true;
      this.imageChangedEvent = event;
    } else {
      this.toasterService.error('', 'Only PNG,GIF,JPG files allowed.');
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    this.showCropper = true;
  }
  loadImageFailed() {
    this.showCropper = false;
  }

  onCancel() {
    document.getElementsByTagName('body')[0].classList.remove('modal-popup');
    document.getElementsByTagName('body')[0].classList.remove('crop-overlay');
    this.showCropper = false;
    this.imageChangedEvent = '';
    this.myInputVariable.nativeElement.value = '';
  }

  onImageCropped(user) {
    const image = this.b64toBlob(this.croppedImage);
    this.setProfileImage(image, user);
    this.showCropper = false;
    this.imageChangedEvent = '';
  }

  b64toBlob(b64Data) {
    const image = b64Data.split(',');
    const contentType = 'image/png';
    const sliceSize = 512;
    const byteCharacters = atob(image[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  setProfileImage(event, user) {
    document.getElementsByTagName('body')[0].classList.remove('modal-popup');
    document.getElementsByTagName('body')[0].classList.remove('crop-overlay');
    this.loading = true;
    const form = new FormData();
    form.append('name', user.name);
    form.append('email', user.email);
    form.append('profile_picture', event);
    this.apiService
      .callPostAPI('v2/customerProfile', form, environment.userApiUrl)
      .subscribe(
        (data) => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.localUser = this.authService.decryption();
            this.localUser['response'] = data['response'];
            this.image = data['response'].profile_picture;
            const userData = this.authService.encryption(this.localUser);
            this.userService.userData(userData);
            localStorage.removeItem('currentUser');
            localStorage.setItem('currentUser', userData);
            this.loading = false;
            this.apiService.successResponse(data);
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }
  fetchPlans() {
    this.apiService
      .callGetAPI('v2/subscriptions', environment.paymentApiUrl)
      .subscribe(
        (data: any) => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.subscriptionPlans = data.response.subscription;
          } else {
            this.apiService.failureResponse(data, true);
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }
  goToHistory() {
    this.router.navigate(['profile/history']);
  }
  goToPlaylist() {
    this.router.navigate(['profile/my-playlist']);
  }
  goToFavourite() {
    this.router.navigate(['profile/favorite-videos']);
  }
}
