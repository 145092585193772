import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ApiService } from '../../../core/services/api.service';
import { AuthService } from '../../../core/auth/auth.service';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../../core/services/user.service';
import {HandleLastItemonHover} from '../../../shared/utils/handlelastitemonhover';
import {UpdateFavouriteIcon} from '../../../shared/utils/updatefavouriteicon';
import { VideoShareService } from '../../../core/services/video-share.service';
declare var $: any;
@Component({
  selector: 'app-season',
  templateUrl: './season.component.html',
  styleUrls: ['./season.component.scss']
})
export class SeasonComponent implements OnInit {
  params: any;
  season_id: any;
  season_data: any;
  userData: any;
  webseries_slug: any;
  webseries_id: any;
  episodes: any;
  seasonlist:any;
  final = true;
  flag = true;
  loading = false;
  page = 1;
  scrollOn: boolean;
  notFound = false;
  noVideo: any;
  sliderMovedData: any;
  sliderRowItem: any;
  more = false;
  moreText = 'More';
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private videoShareService: VideoShareService,
  ) {
     this.season_id = this.route.snapshot.params.season_id;
     this.webseries_id = this.route.snapshot.params.series_slug;
    
   }

  ngOnInit() {
    
    this.scrollToTop();
    this.getSessionVideos();
    
    const userValues = localStorage.getItem('currentUser');
          if (userValues) {
            const userData = this.authService.decryption(userValues);
            this.userData = userData['response'];
            //console.log(this.userData);
          }
  }
  handleTvodLogin(slug) {
    if (localStorage.getItem('currentUser')) {
      
      localStorage.setItem('subscribeUrl', window.location.pathname);
      this.router.navigate(['checkout/ssvod/' + this.webseries_id + '/' + this.season_id]);
      // this.router.navigate(['checkout/tvod/' + this.params+'?series="webseries"']);
      this.userService.redirectUrl('checkout/ssvod/' + this.season_id);
      localStorage.setItem('retryUrl', 'checkout/ssvod/' + this.season_id);
    } else if (!localStorage.getItem('currentUser')) {
      this.userService.displayPopup('sign-in');
      this.userService.redirectUrl('season/' + this.season_id);
    } else {
      // this.userService.redirectUrl('watch/' + this.webseries.webseries_info.slug);
      this.router.navigate(['watch/' + slug]);
    }
  }
  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  detailPageBack() {
    this.router.navigate([
      'webseries/' +
      this.webseries_slug
    ]);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (
      $(window).scrollTop() + $(window).height() > $('footer').offset().top &&
      this.final === false &&
      this.flag === false &&
      this.scrollOn === true
    ) {
      this.loading = true;
      this.flag = true;
      this.getSessionVideos();
    }
  }

  getSessionVideos() {
    this.loading = true;
    this.page = this.page;
    this.apiService
      .callGetAPI(
        'v2/web_season_videos/' + this.webseries_id + '/' + this.season_id+'?page=' + this.page
      )
      .subscribe(
        (data) => {
          if (data['statusCode'] === 200 && data['error'] === false) {

            this.season_data = data['response']['season_data'];

           

            this.season_data.description_small =
            this.season_data.description
              .length > 300
              ? this.season_data.description.substr(
                  0,
                  300
                )
              : this.season_data.description;
          this.more =
            this.season_data.description
              .length > 300
              ? true
              : false;

            this.webseries_slug = this.season_data.webseries_slug;
            if (this.page == 1) {
              this.episodes = [];
            }
            
            if (data['response']['season_list']['data'].length > 0) {
              this.episodes = this.episodes.concat(data['response']['season_list']['data']);
              this.seasonlist = this.episodes.concat(data['response']['season_list']);
              this.seasonlist.total = data['response']['season_list'].total;
              // console.log('this.seasonlist',this.seasonlist);
              // console.log('this.episodes',this.episodes);
              // console.log();
              this.final = data['response'].next_page_url === null ? true : false;
              this.loading = false;
              this.flag = false;
              this.notFound = this.episodes.length > 0 ? false : true;
              this.scrollOn = true;
            } else {
              this.final = true;
              this.loading = false;
              this.flag = false;
              this.scrollOn = false;
            }
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }
  getMoreSeasonVideos() {
    this.loading = true;
    this.page = this.page+1;
    this.apiService
      .callGetAPI(
        'v2/web_season_videos/' + this.webseries_id + '/' + this.season_id+'?page=' + this.page
      )
      .subscribe(
        (data) => {
          if (data['statusCode'] === 200 && data['error'] === false) {

            this.season_data = data['response']['season_data'];
            this.webseries_slug = this.season_data.webseries_slug;
            if (this.page == 1) {
              this.episodes = [];
            }
            
            if (data['response']['season_list']['data'].length > 0) {
              this.episodes = this.episodes.concat(data['response']['season_list']['data']);
              this.seasonlist = this.episodes.concat(data['response']['season_list']);
              this.seasonlist.total = data['response']['season_list'].total;
              this.final = data['response'].next_page_url === null ? true : false;
              this.loading = false;
              this.flag = false;
              this.notFound = this.episodes.length > 0 ? false : true;
              this.scrollOn = true;
            } else {
              this.final = true;
              this.loading = false;
              this.flag = false;
              this.scrollOn = false;
            }
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }
  
  handleLastItemOnHover(event, i: number, section: string) {
    HandleLastItemonHover(event, i, section, this.sliderMovedData);
  }
  updateFavourite(event: any, video) {
    UpdateFavouriteIcon(event, video);
  }
  ngAfterContentInit() {
    this.videoShareService.sliderMovedItems.subscribe((data) => {
      this.sliderMovedData = data;
    });
    this.videoShareService.sliderRowItemsCount.subscribe((data) => {
      this.sliderRowItem = data;
    });
  }

}
