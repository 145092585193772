import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HostListener } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  public categoryDetail = [];
  public subCategories = [];
  constructor() { }
  // user details
  VplyToaster() {
    setTimeout(() => {
      [].forEach.call(document.querySelectorAll('.common-error'), function (el) {
        el.classList.remove('none');
        el.classList.add('block');
      }, 1000);
    });
    setTimeout(() => {
      [].forEach.call(document.querySelectorAll('.common-error'), function (el) {
        el.classList.add('none');
        el.classList.remove('block');
      });
    }, 5000);
  }

  VplyToasterHide() {
    [].forEach.call(document.querySelectorAll('.common-error'), function (el) {
      el.classList.add('none');
      el.classList.remove('block');
    });


  }

  setCategoryDetail(data) {
    this.categoryDetail.push(data);
  }

  getCategoryDetail() {
    return this.categoryDetail;
  }

  setSubCategories(data) {
    this.subCategories.push(data);
  }

  getSubCategories() {
    return this.subCategories;
  }
}
