import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../core/auth/auth.service';
import { UserService } from '../../core/services/user.service';
import { UtilityService } from '../../core/services/utility.service';
import disableScroll from 'disable-scroll';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../core/services/api.service';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  user: any = {};
  submitted = false;
  usernameMaxLength: any = 100;
  pwdMinLength: any = 6;
  pwdMaxlength: any = 60;
  LoggedIn = localStorage.getItem('currentUser');
  error: any = {};
  buttonText: any;
  commonError: string;
  show: Boolean = false;
  phoneError: Boolean = false;
  isVisible: boolean = false;
  otp: Boolean = false;
  showOtpField: Boolean = false;
  otp_value: string;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toasterService: ToastrService,
    private userService: UserService,
    private utilityService: UtilityService,
    private translate: TranslateService,
    private apiService: ApiService,
  ) {
    this.translate.get('SIGN_IN.SIGN_IN').subscribe(res => {
      this.buttonText = res;
    });
   }

  ngOnInit() {
    if (this.LoggedIn) {
      this.router.navigate(['home']);
    }
  }
  login() {
    this.submitted = true;
    this.translate.get('SIGN_IN.SIGNINNG').subscribe(res => {
      this.buttonText = res;
    });
    this.user.provider = 'NORMAL';

   
    this.phoneError = false;
    if (/^\+233\d{9}/.test(this.user.email)) {
    
      this.authService.phone_login(this.user)
        .subscribe(
          data => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              disableScroll.off();
              this.userService.displayPopup('close');
              if (data['response'].name) {
                const userData = this.authService.encryption(data);
                localStorage.setItem('currentUser', userData);
                localStorage.setItem('userName', data['response'].name);
                localStorage.setItem('userEmail', data['response'].email);
                localStorage.setItem('screenImage', data['response'].screenImage);
              }
              this.translate.get('SIGN_IN.SIGN_IN').subscribe(res => {
                this.buttonText = res;
              });

              this.userService.nextPage.subscribe(
                nextUrl => {


                  localStorage.setItem('nextUrl', nextUrl);  //Setting url slug so i use to manage-screen

                  if (!localStorage.getItem('myUrl')) {
                    if (nextUrl.indexOf('video') !== -1) {
                      window.location.reload();
                    } else {
                      if (nextUrl === '/' || nextUrl === '/home' || !localStorage.getItem('screen-id')) {

                        this.router.navigate(['/manage-profiles']);

                      } else if (data['response'].is_subscribed === 1 && localStorage.getItem('subscribeUrl')) {

                        this.router.navigate([localStorage.getItem('subscribeUrl').replace('video/', 'watch/')]);
                      } else {

                        this.router.navigate([nextUrl]);
                      }
                    }
                  } else if (localStorage.getItem('myUrl')) {
                    if (data['response'].is_subscribed === 0) {
                      localStorage.setItem('subscribeUrl', window.location.pathname);
                      this.router.navigate(['subscription']);
                      localStorage.removeItem('myUrl');
                    } else if (data['response'].is_subscribed === 1) {
                      this.router.navigate([window.location.pathname.replace('video/', 'watch/')]);
                      localStorage.removeItem('myUrl');
                    }
                  }
                });
            }
          },
          error => {
            this.submitted = false;
            this.phoneError = false;
            this.translate.get('SIGN_IN.SIGN_IN').subscribe(res => {
              this.buttonText = res;
            });
            if (error.error.statusCode === 422) {
              
              if (typeof error.error.message === 'string' || error.error.message instanceof String) {
                this.commonError = (error.error.message) ? error.error.message : error.message;
                this.utilityService.VplyToaster();
                this.otp = true;
                console.log("Errro 1 ---", error.error.message, error.message)
              } else {
                this.error = error.error.message;
                console.log("Errro 2 ---", error.error.message, error.message)
               
              }
            } else {
              this.error = {};
              this.toasterService.error('', (error.error.message) ? error.error.message : error.message);

              console.log("Errro else ---", error.error.message, error.message )
            }
          }
        );
    }
    else if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.user.email)) {

      this.phoneError = false;
       this.authService.login(this.user)
      .subscribe(
        data => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            disableScroll.off();
            this.userService.displayPopup('close');
            if (data['response'].name) {
              const userData = this.authService.encryption(data);
              localStorage.setItem('currentUser', userData);
              localStorage.setItem('userName', data['response'].name);
              localStorage.setItem('userEmail', data['response'].email);
              localStorage.setItem('screenImage', data['response'].screenImage);
            }
            this.translate.get('SIGN_IN.SIGN_IN').subscribe(res => {
              this.buttonText = res;
            });
           
            this.userService.nextPage.subscribe(
              nextUrl => {
                

                localStorage.setItem('nextUrl', nextUrl);  //Setting url slug so i use to manage-screen

                if (!localStorage.getItem('myUrl')) {
                  if (nextUrl.indexOf('video') !== -1) {
                    window.location.reload();
                  } else {
                    if (nextUrl === '/' || nextUrl === '/home' || !localStorage.getItem('screen-id')) {
                     
                      this.router.navigate(['/manage-profiles']);
                    
                    } else if (data['response'].is_subscribed === 1 && localStorage.getItem('subscribeUrl')) {
                   
                      this.router.navigate([localStorage.getItem('subscribeUrl').replace('video/', 'watch/')]);
                    } else {
                    
                      this.router.navigate([nextUrl]);
                    }
                  }
                } else if (localStorage.getItem('myUrl')) {
                  if (data['response'].is_subscribed === 0) {
                    localStorage.setItem('subscribeUrl', window.location.pathname);
                    this.router.navigate(['subscription']);
                    localStorage.removeItem('myUrl');
                  } else if (data['response'].is_subscribed === 1) {
                    this.router.navigate([window.location.pathname.replace('video/', 'watch/')]);
                    localStorage.removeItem('myUrl');
                  }
                }
              });
          }
        },
        error => {
          this.submitted = false;
          this.translate.get('SIGN_IN.SIGN_IN').subscribe(res => {
            this.buttonText = res;
          });
          if (error.error.statusCode === 422) {
            if (typeof error.error.message === 'string' || error.error.message instanceof String) {
              this.commonError = (error.error.message) ? error.error.message : error.message;
              this.utilityService.VplyToaster();
            } else {
              this.error = error.error.message;
             
            }
          } else {
            this.error = {};
            this.toasterService.error('', (error.error.message) ? error.error.message : error.message);
          }
        }
      );
    }
    else {
      this.submitted = false;
      this.translate.get('SIGN_IN.SIGN_IN').subscribe(res => {
        this.buttonText = res;
      });
      this.phoneError = true;
      
    }
   
  }


  enterOtp() {
   


    this.apiService.callPostAPI('v2/auth/send/otp', {
      phone_number: this.user.email,
    }, environment.userApiUrl)
      .subscribe(
        data => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.submitted = true;
            this.showOtpField = true;

          } else {

            this.submitted = false;
          }
        },
        error => {
          this.submitted = false;
          this.toasterService.error('', 'Could not send OTP to mobile number');
        });
   
  }


  toPassword() {
   

    console.log("OTP VALUE::", this.otp_value, "Phone", this.user.email)

    this.apiService.callPostAPI('v2/auth/verify/otp', {
      phone_number: this.user.email,
      otp: this.otp_value,
      activate: true
    }, environment.userApiUrl)
      .subscribe(
        data => {
          this.submitted = false;
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.toasterService.success('', data['message'], {
              timeOut: 15000,
            });
            this.userService.displayPopup('colse');
            disableScroll.off();
            this.translate.get('SIGN_UP.CREATE_ACCOUNT').subscribe(res => {
              this.buttonText = res;
            });

            this.userService.nextPage.subscribe(
              nextUrl => {
                if (!localStorage.getItem('myUrl')) {
                  if (nextUrl.indexOf('video') !== -1) {
                    window.location.reload();
                  } else {
                    this.router.navigate([nextUrl]);
                  }
                } else if (localStorage.getItem('myUrl')) {
                  if (data['response'].is_subscribed === 0) {
                    localStorage.setItem('subscribeUrl', window.location.pathname);
                    this.router.navigate(['subscription']);
                    localStorage.removeItem('myUrl');
                  } else if (data['response'].is_subscribed === 1) {
                    this.router.navigate([window.location.pathname.replace('video/', 'watch/')]);
                    localStorage.removeItem('myUrl');
                  }
                }
              });

          } else {
            this.submitted = false;
          }
        },
        error => {
          this.submitted = false;
          this.toasterService.error('', 'OTP could not be verified. Kindly try again');
        });


  }

  onChange(e) {
    this.error[e.target.name] = '';
  }

  onClose() {

    this.isVisible = true
   
    disableScroll.off();
    if (this.route.snapshot['_routerState'].url === '/sign-in') {
      this.router.navigate(['home']);
    }
    this.userService.displayPopup('close');
  }

  onClick(value: string) {
    if (value === 'forget') {
      this.userService.displayPopup('forget');
    } else {
      this.userService.displayPopup('sign-up');
    }
  }
  VplyToasterHide() {
    this.utilityService.VplyToasterHide();
  }
  toggleShowPassword() {
    this.show = !this.show;
  }
}
