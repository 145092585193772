import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SubscriptionModel, PaymentModel } from '../../../modules/subscription/subscription.model';
import { ApiService } from '../../../core/services/api.service';
import { UserService } from '../../../core/services/user.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-price-card',
  templateUrl: './price-card.component.html',
  styleUrls: ['./price-card.component.scss']
})
export class PriceCardComponent {
  @Input() plan: SubscriptionModel;
  subscription: PaymentModel = {
    payment_method_id: 1,
    subscription_plan_id: ''
  };
  loggedIn: any = {};
  @Output() subscribed = new EventEmitter();

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private router: Router,
  ) { }

  subscribeNow() {
    this.loggedIn = localStorage.getItem('currentUser');
    if (this.loggedIn) {
      this.subscription.subscription_plan_id = this.plan.slug;
      this.apiService.callPostAPI('v2/subscription/add', this.subscription, environment.paymentApiUrl)
        .subscribe(
          data => {
            this.apiService.successResponse(data);
            this.subscribed.emit();
          },
          error => {
            this.apiService.failureResponse(error, true);
          });
    } else {
      this.userService.redirectUrl(this.router.url);
      this.userService.displayPopup('sign-in');
    }
  }
}
