import {
  Component,
  OnInit,
  OnDestroy,
  AfterContentInit,
  HostListener,
  ViewChild,
  ElementRef,
  RendererFactory2,
  Inject
} from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { environment } from '../../../environments/environment';
import disableScroll from 'disable-scroll';
import { HeaderService } from '../../core/services/header.service';
import { AuthService } from '../../core/auth/auth.service';
import { VideoShareService } from '../../core/services/video-share.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../core/services/user.service';
import {UpdateFavouriteIcon} from '../../shared/utils/updatefavouriteicon';
import { HandleLastItemonHover } from '../../shared/utils/handlelastitemonhover';
import { HttpClient } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';

declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterContentInit {
  private renderer;
  trending: any = [];
  new: any = [];
  user: any = {};
  webseries: any = [];
  banner: any = [];
  section: any = [];
  genre: any = [];
  home: any = [];
  signupuserData: any;
  routeSub: any;
  load: Boolean;
  defaultOwlCarousel: any = [];
  scrollEvent = true;
  viewPortEnable: Boolean = false;
  moved: any = 7;
  page = 1;
  newNextPage: Boolean = true;
  trendingNextPage: Boolean = true;
  previousPage: Boolean = false;
  sliderBox: any;
  nextMove: any;
  activeItem: any;
  continueWatchingData: any;
  ItemNumber = 7;
  trendingPage = 1;
  onSignupScreenId: any;
  loadingSlider: any = [];
  sectionPage: any = [];
  sectionNextPage: any = [];
  categoriesVideos: any = [];
  search: any = false;
  homeSection: Boolean = true;
  homeSectionOne: Boolean = true;
  homeSectionTwo: Boolean = true;
  site_link: any = [];
  getHomeCategory;
  getRecommendedVideos;
  currentDecryptUser: any;
  sliderMovedData: any;
  sliderRowItem: any;
  error: any = {};
  screen: any;
  screens: any;
  screenId: any = {};
  currentscreenId: any;
  localUser: any;
  signIn_phone = false;


  @HostListener('window:scroll', ['$event'])
  onscroll() {
    if (this.homeSection === false) {
      this.homeSection = true;
      if (!this.getHomeCategory) {
        this.getHome();
      }
    }
    // if (this.homeSectionOne === true) {
    //   this.getHomeSectionOne();
    //   this.homeSectionOne = false;
    // }
    // if (this.homeSectionTwo === true) {
    //   this.getHomeSectionTwo();
    //   this.homeSectionTwo = false;
    // }
  }
  constructor(
    private apiService: ApiService,
    private router: Router,
    private headerService: HeaderService,
    private authService: AuthService,
    private videoShareService: VideoShareService,
    private route: ActivatedRoute,
    private toasterService: ToastrService,
    private userService: UserService,
    private http: HttpClient

  ) {

    this.continueWatchingData = [];

    this.routeSub = this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        this.fetchwatchVideo();
        this.checkAPIAuthorization();
      }
    });
  }


  // openModal(): void {
  //   // Pass the external HTML file URL to the modal service
  //   this.userService.openModal('assets/vodafoneBilling.html');

  //   this.http.get('assets/vodafoneBilling.html', { responseType: 'text' }).subscribe(
  //     (content) => {
  //       this.userService.openModal(content);
  //     },
  //     (error) => {
  //       console.error('Error fetching content:', error);
  //     }
  //   );
  // }



  ngOnInit() {

    this.checkMarketingUrl();

    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.route.params.subscribe((params) => {
      if (params && params['token']) {
        this.activateUser(params['token']);
      }
      if (params && params['permitcode']) {
        this.permitScreen(params['permitcode']);
      }
    });

    this.currentDecryptUser = this.authService.decryption(
      localStorage.getItem('currentUser')
    );
    this.getHomeCategory = this.headerService.getHomeCategory();


    this.genre.data = [];
    this.new.data = [];
    this.webseries.data = [];
    this.categoriesVideos = [];
    this.sectionPage = [];
    this.sectionNextPage = [];
    this.trending.data = [];
    this.loadingSlider.data = [];
    for (let i = 0; i <= 6; i++) {
      this.loadingSlider.data.push({
        thumbnail_image: environment.defaultImage
      });
    }
    this.getSiteLinks();

    this.headerService.bannerData$.subscribe((data) => {
      this.banner = data;
      if (this.banner === null) {
        this.headerService.fetchBanner();
      }
    });
    this.headerService.newData$.subscribe((data) => {
      this.new = data;
    });
    this.headerService.webseriesData$.subscribe((data) => {
      this.webseries = data;
    });
    this.homeSection = false;
    //this.homeSectionOne = false;
    //this.homeSectionTwo = false;
    if (this.getHomeCategory) {
      this.getHomeAfterData(this.getHomeCategory);
    }

    // Popup details Observable
    this.userService.display.subscribe((data) => {
      this.onShowHide(data);
    });
  }
  ngAfterContentInit() {
    this.videoShareService.sliderMovedItems.subscribe((data) => {
      this.sliderMovedData = data;
    });
    this.videoShareService.sliderRowItemsCount.subscribe((data) => {
      this.sliderRowItem = data;
    });
  }

  onVideoClick(videoSlug: string) {
    this.router.navigate(['watch/' + videoSlug]);
  }

  checkMarketingUrl() {
    // Popup details Observable
    // this.userService.display.subscribe((data) => {
    //   this.onShowHide(data);
    // });


    const currentUrl = this.router.url;

    // Check if the URL contains '/marketing'
    if (currentUrl.includes('/marketing')) {
     // this.signIn = true;
      this.onShowHide('phone-sign-in');
    }
    else if (currentUrl.includes('/marketing=true')) {

      // this.signIn = true;
      this.onShowHide('phone-sign-in');
    }
    else if (currentUrl.includes('/?marketing=true')) {

      // this.signIn = true;
      this.onShowHide('phone-sign-in');
    }
  }

  getHome() {
    this.currentscreenId = localStorage.getItem('device-id');
    this.load = false;
    this.apiService.callGetAPI('v2/home_page').subscribe(
      (data) => {

        this.headerService.setHomeCategory(data);
        this.getHomeAfterData(data);
        //this.homeSectionOne = true;
        if (data['response'].currentScreenId == null) {
          this.authService.logout(true);
          localStorage.removeItem('couponResponse');
          localStorage.removeItem('userName');
          localStorage.removeItem('userEmail');
        }
      },
      (error) => {
      }
    );
    //console.log(this.homeSectionOne);

  }
  // getHomeSectionOne() {
  //   this.currentscreenId = localStorage.getItem('device-id');
  //   this.load = false;
  //   this.apiService.callGetAPI('v2/home_page_section_one').subscribe(
  //     (data) => {
  //       this.headerService.setHomeCategory(data);
  //       this.getHomeAfterData(data);
  //       this.homeSectionTwo = true;
  //       if(data['response'].currentScreenId==null){
  //         this.authService.logout(true);
  //         localStorage.removeItem('couponResponse');
  //         localStorage.removeItem('userName');
  //         localStorage.removeItem('userEmail');
  //       }
  //     },
  //     (error) => {
  //     }
  //   );
  // }
  // getHomeSectionTwo() {
  //   this.currentscreenId = localStorage.getItem('device-id');
  //   this.load = false;
  //   this.apiService.callGetAPI('v2/home_page_section_two').subscribe(
  //     (data) => {
  //       this.headerService.setHomeCategory(data);
  //       this.getHomeAfterData(data);
  //       this.homeSectionTwo = false;
  //       if(data['response'].currentScreenId==null){
  //         this.authService.logout(true);
  //         localStorage.removeItem('couponResponse');
  //         localStorage.removeItem('userName');
  //         localStorage.removeItem('userEmail');
  //       }
  //     },
  //     (error) => {
  //     }
  //   );
  // }

  getHomeAfterData(data) {
    if (data['statusCode'] === 200 && data['error'] === false) {
      let index = 0;
      for (const datas of data['response'].home_content) {
        this.categoriesVideos.push(datas);
        this.sectionPage.push(1);
        if (datas.category_slug == 'pay-per-view') {
          if ((datas['rental_videos'].total + datas['rental_webseries'].total) === this.categoriesVideos[index].rental_videos.data.length + this.categoriesVideos[index].rental_webseries.data.length) {
            this.sectionNextPage[index] = false;
          } else {
            this.sectionNextPage[index] = true;
          }
        } else {
          if (datas.total === this.categoriesVideos[index].data.length) {
            this.sectionNextPage[index] = false;
          } else {
            this.sectionNextPage[index] = true;
          }
        }
        index++;
      }
      this.load = true;
    }
  }

  next(url) {
    if (url === 'new') {
      this.nextObj();
    } else if (url === 'trending') {
      this.nextObjTrending();
    }
  }
  nextObj() {
    this.page++;
    if (!(this.new.data.length === this.new.total)) {
      this.apiService
        .callGetAPI('v2/home_more?type=new&page=' + this.page)
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].total === this.new.data.length) {
                this.newNextPage = false;
              }
              data['response'].data.forEach((value) => {
                this.new.data.push(value);
              });
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    } else {
    }
  }

  nextObjTrending() {
    this.trendingPage++;
    if (this.trendingNextPage) {
      this.apiService
        .callGetAPI('v2/home_more?type=trending&page=' + this.trendingPage)
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].total === this.trending.data.length) {
                this.trendingNextPage = false;
              }
              data['response'].data.forEach((value) => {
                this.trending.data.push(value);
              });
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    }
  }
  sectionNext(section, index) {
    if (this.sectionNextPage[index] === true) {
      this.sectionPage[index] += 1;
      this.apiService
        .callGetAPI(
          'v2/home_more?type=' + section + '&page=' + this.sectionPage[index]
        )
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              data['response'].data.forEach((value) => {
                this.categoriesVideos[index].data.push(value);
              });
              if (
                data['response'].total ===
                this.categoriesVideos[index].data.length
              ) {
                this.sectionNextPage[index] = false;
              }
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    }
  }

  getSiteLinks() {
    this.headerService.apiData$.subscribe((data) => {
      if (data !== null) {
        this.site_link = data['response'].site_link;
      }
    });
    if (this.site_link === null) {
      setTimeout(() => {
        this.getSiteLinks();
      }, 1000);
    }
  }
  ngOnDestroy() {
    disableScroll.off();
    this.routeSub.unsubscribe();
  }

  google_play_clicked() {
    if (this.site_link.android_app_link) {
      const a = document.createElement('a');
      a.target = "_blank";
      a.href = this.site_link.android_app_link ? this.site_link.android_app_link : undefined;
      a.click();
    }
  }
  app_store_clicked() {
    if (this.site_link.ios_app_link) {
      const a = document.createElement('a');
      a.target = "_blank";
      a.href = this.site_link.ios_app_link ? this.site_link.ios_app_link : undefined;
      a.click();
    }
  }


  onShowHide(value: string) {
    if (value === 'phone-sign-in') {

      this.signIn_phone = true;

    }
  }

  activateUser(key) {
    this.apiService
      .callPostAPI(
        'v2/auth/check_activation_code',
        {
          login_type: 'normal',
          acesstype: 'web',
          key: key
        },
        environment.userApiUrl
      )
      .subscribe(
        (data) => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.userService.displayPopup('close');
            disableScroll.off();
            this.user.provider = 'NORMAL';
            this.user.email = data['response'].email;
            this.user.password = atob(data['response'].password_decrypt);
            this.authService.login(this.user)
              .subscribe(
                data => {
                  if (data['statusCode'] === 200 && data['error'] === false) {
                    disableScroll.off();
                    this.userService.displayPopup('close');
                    if (data['response'].name) {
                      const userData = this.authService.encryption(data);
                      localStorage.setItem('currentUser', userData);
                      localStorage.setItem('userName', data['response'].name);
                      localStorage.setItem('userEmail', data['response'].email);
                      //localStorage.setItem('screenImage', data['response'].screenImage);
                    }

                    // next page Observable
                    this.userService.nextPage.subscribe(
                      nextUrl => {
                        if (!localStorage.getItem('myUrl')) {
                          if (nextUrl.indexOf('video') !== -1) {
                            window.location.reload();
                          } else {
                            this.apiService.callGetAPI('v2/screens', environment.userApiUrl).subscribe(
                              (data) => {
                                if (data['statusCode'] === 200 && data['error'] === false) {
                                  this.screens = data['response']['screens'];
                                  //console.log(this.screens);
                                  if (this.screens.length = 1) {
                                    this.screens.forEach(element => {
                                      this.onSignupScreenId = element._id;
                                    });
                                    this.apiService.callGetAPI('v2/set-screen/' + this.onSignupScreenId, environment.userApiUrl).subscribe(
                                      (data) => {
                                        if (data['statusCode'] === 200 && data['error'] === false) {
                                          //window.location.href = '/subscription';
                                          this.signupuserData = this.authService.decryption(
                                            localStorage.getItem('currentUser')
                                          );
                                          this.signupuserData['response'].screen = this.onSignupScreenId;
                                          const userNewData = this.authService.encryption(this.signupuserData);
                                          localStorage.setItem('currentUser', userNewData);
                                          localStorage.setItem('screen-id', this.onSignupScreenId);
                                          this.router.navigate(['/subscription']);
                                        }
                                        this.router.navigate(['/subscription']);
                                      },
                                      (error) => {
                                        this.router.navigate(['/home']);
                                        this.toasterService.error('', error.error.message);
                                      }
                                    );
                                    this.router.navigate(['/subscription']);
                                  }
                                }
                              },
                              (error) => {
                                this.apiService.failureResponse(error, true);
                                this.router.navigate(['/home']);
                              }
                            );
                            // if(nextUrl == '/' || nextUrl == '/home'){
                            //   this.router.navigate(['/manage-profiles']);
                            // } else {
                            //   this.router.navigate([nextUrl]);
                            // }
                          }
                        } else if (localStorage.getItem('myUrl')) {
                          if (data['response'].is_subscribed === 0) {
                            localStorage.setItem('subscribeUrl', window.location.pathname);
                            this.router.navigate(['subscription']);
                            localStorage.removeItem('myUrl');
                          } else if (data['response'].is_subscribed === 1) {
                            this.router.navigate([window.location.pathname.replace('video/', 'watch/')]);
                            localStorage.removeItem('myUrl');
                          }
                        }
                      });
                  }
                },
                error => {
                  //this.submitted = false;
                  // this.translate.get('SIGN_IN.SIGN_IN').subscribe(res => {
                  //   this.buttonText = res;
                  // });
                  if (error.error.statusCode === 422) {
                    if (typeof error.error.message === 'string' || error.error.message instanceof String) {
                      this.error = (error.error.message) ? error.error.message : error.message;
                      this.toasterService.error('', this.error);
                    } else {
                      this.error = error.error.message;
                    }
                  } else {
                    this.error = {};
                    this.toasterService.error('', (error.error.message) ? error.error.message : error.message);
                  }
                }
              );
            // this.toasterService.success('', data['message']);
            // this.router.navigate(['/home']);
          }
        },
        (error) => {
          if (error.error.statusCode === 422) {
            this.error = error.error.message;
            this.toasterService.error('', this.error);
            this.router.navigate(['/home']);
          } else {
            this.error = {};
            this.toasterService.error(
              '',
              error.error.message ? error.error.message : error.message
            );
            this.router.navigate(['/home']);
          }
        }
      );
  }
  fetchwatchVideo() {
    if (localStorage.getItem('currentUser')) {
      const userData = this.authService.decryption(
        localStorage.getItem('currentUser')
      );
      this.screen = userData['response'] !== undefined ? userData['response'].screen : '';

      // If logged in then hit the API
      this.apiService.callGetAPI('v2/continue_watching').subscribe(
        (data) => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.continueWatchingData = data['response'];
          }
        },
        (error) => {
        }
      );
    } else {
      this.continueWatchingData = [];
    }

  }
  /**
   * Method to add class to parent div when it is hovered
   * @param number i
   */
  handleLastItemOnHover(event, i: number, section: string) {
    HandleLastItemonHover(event, i, section, this.sliderMovedData);
  }
  /**
   * Method to handle the favourite icon when the icon is clicked.
   * @param event
   * @param array video
   */
  updateFavourite(event: any, video) {
    UpdateFavouriteIcon(event, video);
  }
  /**
   * This method checks for user authorization like 403, 401 and handle response.
   *
   * @return void
   */
  checkAPIAuthorization() {
    if (localStorage.getItem('currentUser')) {
      // If logged in then hit the API
      this.apiService.callGetAPI('v2/check_authorization', environment.userApiUrl).subscribe(
        (data) => {
        },
        (error) => {
           this.apiService.failureResponse(error);
        }
      );
    }
  }

  permitScreen(key) {
    this.apiService
      .callDeleteAPI(
        'v2/unset-screen/' + key,
        environment.userApiUrl
      )
      .subscribe(
        (data) => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.screenId = data['response'].screen;
            this.toasterService.success('', data['message']);
            disableScroll.off();
            this.router.navigate(['/home']);
            // this.router.navigate(['/manage-profiles']);
          }
        },
        (error) => {
          if (error.error.statusCode === 422) {
            this.error = error.error.message;
            this.toasterService.error('', this.error);
            this.router.navigate(['/home']);
          } else {
            this.error = {};
            this.toasterService.error(
              '',
              error.error.message ? error.error.message : error.message
            );
            this.router.navigate(['/home']);
          }
        }
      );
  }
}
