import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import {
  Validators,
  FormGroup,
  FormControl,
  FormGroupDirective
} from '@angular/forms';
import { UtilityService } from '../../../core/services/utility.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../core/auth/auth.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  user: any = {};
  errors: any = {};
  submitted: boolean;
  form: FormGroup;
  buttonText;
  success: any;
  result = false;
  constructor(
    private apiService: ApiService,
    private utilityService: UtilityService,
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.translate
      .get('CHANGE_PASSWORD.CHAGE_PASSWORD_BUTTON')
      .subscribe((res) => {
        this.buttonText = res;
      });
  }

  ngOnInit() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const userData = this.authService.decryption(
      localStorage.getItem('currentUser')
    );
    if (userData['response'].login_type !== 'Web') {
      this.router.navigate(['home']);
    }
    this.form = new FormGroup(
      {
        old_password: new FormControl('', [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(16)
        ]),
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(16)
        ]),
        password_confirmation: new FormControl('', [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(16)
        ])
      },
      [passwordMatchValidator, passwordNotMatchValidator]
    );

    function passwordMatchValidator(g: FormGroup) {
      return g.get('password').value === g.get('password_confirmation').value
        ? null
        : { mismatch: true };
    }

    function passwordNotMatchValidator(g: FormGroup) {
      return g.get('old_password').value !== g.get('password').value
        ? null
        : { mismatch: true };
    }
  }

  updatePassword(f: FormGroupDirective) {
    this.submitted = true;
    this.translate
      .get('CHANGE_PASSWORD.CHAGE_PASSWORD_BUTTON')
      .subscribe((res) => {
        this.buttonText = res + '..';
      });
    this.apiService
      .callPutAPI('v2/auth/change', this.user, environment.userApiUrl)
      .subscribe(
        (data) => {
          f.resetForm();
          this.submitted = false;
          this.user = {};
          this.form.reset();
          this.translate
            .get('CHANGE_PASSWORD.CHAGE_PASSWORD_BUTTON')
            .subscribe((res) => {
              this.buttonText = res;
            });
          this.result = false;
          this.success = data['message'];
          this.utilityService.VplyToaster();
        },
        (error) => {
          this.submitted = false;
          this.result = false;
          this.translate
            .get('CHANGE_PASSWORD.CHAGE_PASSWORD_BUTTON')
            .subscribe((res) => {
              this.buttonText = res;
            });
          this.errors = this.apiService.failureResponse(error);
          if (this.errors.commonError) {
            this.result = true;
            this.success = this.errors.commonError;
            this.utilityService.VplyToaster();
          }
        }
      );
  }
  onChange(e) {
    this.errors[e.target.name] = '';
  }
  VplyToasterHide() {
    this.utilityService.VplyToasterHide();
  }
}
