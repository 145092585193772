import { Component, HostListener, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Router, Scroll, NavigationEnd, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import 'rxjs/add/operator/filter';
import { Location, ViewportScroller, isPlatformBrowser } from '@angular/common';
import { ConnectionService } from 'ng-connection-service';
import { HeaderService } from './core/services/header.service';
import { AuthService } from './core/auth/auth.service';
import { filter } from 'rxjs/operators';
import { ApiService } from '../app/core/services/api.service';
import { environment } from '../environments/environment';
import { map } from 'rxjs/operators';

declare var $: any;
declare var ga: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'VPLAY';
  routes: any;
  headerFlag: Boolean;
  footerFlag: Boolean;
  isConnected: any;
  status: any = true;
  userData: any = {};
  loading = true;
  constructor(
    private authService: AuthService, 
    private router: Router,
    private connectionService: ConnectionService,
    private headerService: HeaderService,
    private loc: Location,
    private viewportScroller: ViewportScroller,
    private apiService: ApiService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    
    if (!localStorage.getItem('currentLanguage')) {
      localStorage.setItem('currentLanguage', 'en');
    }
    this.router.events.subscribe((res) => {});
    this.connectionService.monitor().subscribe((isConnected) => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = true;
      } else {
        this.status = false;
      }
    });
    router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        if (event instanceof NavigationEnd) {
          ga('set', 'page', event.urlAfterRedirects);
          ga('send', 'pageview');
          this.routes = this.router.url;
          if (
            this.routes.startsWith('/watch/') ||
            this.routes.startsWith('/embed/') ||
            this.routes.startsWith('/watch-trailer/')
            ) {
            this.headerFlag = false;
          } else {
            this.headerFlag = true;
          }
          if (
            this.routes.startsWith('/watch/') ||
            this.routes.startsWith('/embed/') ||
            this.routes.startsWith('/watch-trailer/')
            ) {
            this.footerFlag = false;
          } else {
            this.footerFlag = true;
          }
        }
      });

    this.router.events
      .pipe(filter((e) => e instanceof Scroll))
      .subscribe((e: any) => {
        setTimeout(() => {
          if (e.position) {
            this.viewportScroller.scrollToPosition(e.position);
          } else if (e.anchor) {
            this.viewportScroller.scrollToAnchor(e.anchor);
          }
        });
      });
    
    

    const queryParams = new URLSearchParams(window.location.search);

    if (queryParams.get('marketing') === 'true') {
      // Redirect to marketing page
      this.router.navigate(['/marketing']);
    }
  
  }

 

  ngOnInit() {

    if (isPlatformBrowser(this.platformId)) {
      this.removePreloader(); // Remove preloader once Angular is ready

      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          this.loading = true;
          console.log("Navigation start loafin", this.loading)
          this.showPreloader();
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.loading = false;
          console.log("Navigation end loafin", this.loading)
          this.removePreloader();
        }
      });
    } 

    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationStart) {
    //     this.loading = true; // Show loader at the start of navigation
    //   } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
    //     this.loading = false; // Hide loader when navigation is complete
    //   }
    // });
    
    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationStart)
    // ).subscribe(() => {
    //   this.loading = true;
    //   console.log("Navigation start loafin", this.loading )
      
    // });

    // // Hide preloader after navigation ends
    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe(() => {
    //   this.loading = false;
    //   console.log("Navigation end loafin", this.loading)
     
    // });
    //this.doSomething();
  }


  showPreloader() {
    console.log("showPreloader")
    const preloader = document.getElementById('preloader');
    if (preloader) {
      preloader.style.display = 'block';
      preloader.style.opacity = '1';
    }
  }

  removePreloader() {
    console.log("removePreloader")
    const preloader = document.getElementById('preloader');
    if (preloader) {
      preloader.style.opacity = '0';
      setTimeout(() => {
        preloader.remove(); // Fully remove after transition
      }, 500); // Allow time for fade-out transition
    }

  }


  changeOfRoute() {
   

    // console.log(this.userData['response'].screen,'screens1');
    // console.log(localStorage.getItem('currentUser'),'currentUser');
    if(localStorage.getItem('currentUser')){
      this.userData = this.authService.decryption(
        localStorage.getItem('currentUser')
      );
      // console.log(this.userData['response'].screen,'screenss');
      if (this.userData['response'].screen === '') {
        
        this.routes = this.router.url;
        if (!this.routes.startsWith('/manage-profiles')){
          this.router.navigate(['/manage-profiles']);
        }
      } else {
        
        return true;
      }
    }
    return true;
  }
}
