import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import videoJs from '../../../../assets/js/play.es.js';
import { ApiService } from '../../../core/services/api.service';
import { ConnectionService } from 'ng-connection-service';
@Component({
  selector: 'app-trailer',
  templateUrl: './trailer.component.html',
  styleUrls: ['./trailer.component.scss']
})
export class TrailerComponent implements OnInit, OnDestroy {
  trailerPlayer: any;
  trailerVideo: any;
  newtworkStatus: any;
  params: any;
  videoParam: any;
  isConnected = true;
  status = 'ONLINE';
  constructor(
    private router: Router,
    private activated_route: ActivatedRoute,
    private apiService: ApiService,
    private connectionService: ConnectionService,
  ) {
    this.activated_route.params.subscribe((params) => {
      this.videoParam = params;
      this.params = params;
    });
    this.connectionService.monitor().subscribe((isConnected) => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = 'ONLINE';
      } else {
        this.status = 'OFFLINE';
        this.newtworkStatus = document.getElementById('online-toast');
        document.getElementById('videos').appendChild(this.newtworkStatus);
      }
    });
  }

  ngOnInit() {
    this.trailerData();
    document.getElementsByTagName('body')[0].classList.add('body-video-page');
  }
  /**
   * Call to watch-trailer/:slug api endpoint to fetch trailer related details.
   *
   * @return void;
   */
  trailerData() {
     this.apiService.callGetAPI('v2/watchtrailer/' +  this.videoParam['video_id']).subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.trailerVideo = data['response']['data'];
          this.setPlayerConfig();
        }
      },
      (error: any) => {
          this.apiService.failureResponse(error);
        }
    );
  }
  /**
   * Method to set trailer player configuration
   *
   * @return void
   */
  setPlayerConfig() {
    const getVideoElem = document.getElementById('videos');
    this.trailerPlayer = videoJs(getVideoElem, {
      playbackRates: [0.25, 0.5, 1, 1.25, 1.5, 2],
      controls: true,
      preload: 'auto',
      fluid: true,
      autoplay: false,
      seekEventPercentage: 30,
      html5: {
        nativeAudioTracks: false,
        nativeVideoTracks: false,
        nativeTextTracks: false,
        hls: {
          overrideNative: true,
        }
      },
      plugins: {
        hlsQualitySelector: {},
        seekButtons: {
          forward: 10,
          back: 10
        },
        keyboardShortCuts: {},
        spriteThumbnails: {}
      }
    });
    this.newtworkStatus = document.getElementById('online-toast');
    document.getElementsByClassName('play-text-track-display')[0].innerHTML =
      '<div>' + this.trailerVideo.title + '</div>';
      getVideoElem.appendChild(this.newtworkStatus);
    this.trailerPlayer.src({
      src: this.trailerVideo.trailer_hls_url,
      type: 'application/x-mpegURL'
    });
    this.trailerPlayer.play();
  }
  ngOnDestroy() {
    document.getElementsByTagName('body')[0].classList.remove('body-video-page');
  }
  PageBack() {
    this.router.navigate(['video/' + this.params.video_id]);
  }
}
