import { Component, OnInit, HostListener } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { UserService } from '../../../core/services/user.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { environment } from '../../../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent implements OnInit {
  transaction_id = '';
  plan_name = '';
  transactions: any = [];
  format = 'MMM dd y hh:mm a';
  rowsPerPage = 10;
  typerowsPerPage = 10;
  page = 0;
  final = false;
  type_final = false;
  flag = false;
  type_flag = false;
  loading = false;
  notFound = false;
  sno = 0;
  payment_type: any = 'web';
  type_page = 0;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (
      $(window).scrollTop() + $(window).height() > $('footer').offset().top &&
      this.final === false &&
      this.flag === false && this.type_flag === true && this.type_final === true
    ) {
      this.flag = true;
      this.getTransactions();
    }
    if (
      $(window).scrollTop() + $(window).height() > $('footer').offset().top &&
      this.type_final === false &&
      this.type_flag === false && this.flag === true && this.final === true
    ) {
      this.type_flag = true;
      this.getTransactionsByType(false, this.payment_type);
    }
  }

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit() {
    $('.profile-tittle').addClass('my-transaction');
    this.transactions = [];
    this.getTransactions();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  rowsPerPageChange(event: any) {
    this.transactions = [];
    this.page = 0;
    this.rowsPerPage = event.target.value;
    this.getTransactions();
  }
  changePaymentType(e) {
    this.page = 0;
    this.type_page = 0;
    this.transactions = [];
    this.payment_type = e.target.value;
   
    this.type_flag = true;
    this.type_final = true;
    this.flag = true;
    this.final = true;
      this.getTransactionsByType(false, e.target.value);
  }
  getTransactionsByType(refresh = false , type) {
    //this.loading = true;
    this.type_page = this.type_page + 1;
    if (refresh) {
      this.transaction_id = '';
      this.plan_name = '';
    }
    this.apiService
      .callPostAPI(
        'v2/transactions/records',
        {
          filter: null,
          filters: {},
          intialRequest: 0,
          orderByFieldName: null,
          page: this.type_page,
          type: this.payment_type,
          rowsPerPage: this.typerowsPerPage,
          searchRecord: !refresh
            ? { transaction_id: this.transaction_id, plan_name: this.plan_name }
            : ''
        },
        environment.paymentApiUrl
      )
      .subscribe(
        (data) => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.transactions = this.transactions.concat(data['data'].data);
            this.transactions.forEach((value, index) => {
              if(value.series_id != '' || value.season_id != ''){
                this.apiService.callGetAPI('v2/web_season_videos/' + value.category.slug + '/' + value.season_id).subscribe(
                  (data) => {
                    if (data['statusCode'] === 200 && data['error'] === false) {
                      
                      value['season_data'] = data['response']['season_data'];
                    }
                  },
                  (error) => {
                    this.apiService.failureResponse(error);
                  }
                );
              }else{
                value['season_data'] = [];
              }
            });
            this.sno = 0;
            this.type_final = data['data'].next_page_url === null ? true : false;
            this.payment_type = data['type'];
            this.loading = false;
            this.type_flag = false;
            this.notFound = this.transactions.length > 0 ? false : true;
          }
        },
        (error) => {
          this.apiService.failureResponse(error);
        }
      );
  }
  searchFilter() {
    this.transactions = [];
    this.page = 0;
    this.getTransactions();
  }

  refrsh() {
    this.transactions = [];
    this.page = 0;
    this.getTransactions(true);
  }

  getTransactions(refresh = false) {
    this.loading = true;
    this.page = this.page + 1;
    this.payment_type = null;
    if (refresh) {
      this.transaction_id = '';
      this.plan_name = '';
    }
    this.apiService
      .callPostAPI(
        'v2/transactions/records',
        {
          filter: null,
          filters: {},
          intialRequest: 0,
          orderByFieldName: null,
          page: this.page,
          rowsPerPage: this.rowsPerPage,
          searchRecord: !refresh
            ? { transaction_id: this.transaction_id, plan_name: this.plan_name }
            : ''
        },
        environment.paymentApiUrl
      )
      .subscribe(
        (data) => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.transactions = this.transactions.concat(data['data'].data);
            this.transactions.forEach((value, index) => {
              if(value.series_id != '' || value.season_id != ''){
                this.apiService.callGetAPI('v2/web_season_videos/' + value.category.slug + '/' + value.season_id).subscribe(
                  (data) => {
                    if (data['statusCode'] === 200 && data['error'] === false) {
                      
                      value['season_data'] = data['response']['season_data'];
                    }
                  },
                  (error) => {
                    this.apiService.failureResponse(error);
                  }
                );
               
              }else{
                value['season_data'] = [];
              }
            });
            this.sno = 0;
            this.final = data['data'].next_page_url === null ? true : false;
            this.loading = false;
            this.flag = false;
            this.payment_type = data['type'];
            this.notFound = this.transactions.length > 0 ? false : true;
          }
        },
        (error) => {
          this.apiService.failureResponse(error);
        }
      );
  }
  goToVideo(slug) {
    this.router.navigate(['video/' + slug]);
  }
}
