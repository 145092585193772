import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  OnDestroy
} from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { AuthService } from '../../../core/auth/auth.service';
import { UtilityService } from '../../../core/services/utility.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormGroupDirective } from '@angular/forms';
import disableScroll from 'disable-scroll';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-my-playlist',
  templateUrl: './my-playlist.component.html',
  styleUrls: ['./my-playlist.component.scss']
})
export class MyPlaylistComponent implements OnInit, OnDestroy {
  playlist: any = [];
  playlistFlag: any = [];
  playlistDrop: any;
  buttonText: any;
  playlist_name: any;
  errors: any;
  submitted: boolean;
  result: boolean;
  success: any;
  final: boolean;
  scrollOn: boolean;
  flag: boolean;
  loading: boolean;
  page: any = 0;
  noPlaylist: Boolean;
  userData: any;
  constructor(
    private authService: AuthService, 
    private apiService: ApiService,
    private eRef: ElementRef,
    private utilityService: UtilityService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.userData = this.authService.decryption(
      localStorage.getItem('currentUser')
    );
    this.translate.get('MY_PLAYLIST.UPDATE_PLAYLIST').subscribe((res) => {
      this.buttonText = res;
    });
  }

  @HostListener('document:click', ['$event'])
  clickOut() {
    if (event.target !== this.playlistDrop) {
      for (let i = 0; i < this.playlist.length; i++) {
        document
          .getElementById('drop' + this.playlist[i]._id)
          .classList.remove('show');
      }
      this.playlistDrop = undefined;
      disableScroll.off();
    }
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (
      $(window).scrollTop() + $(window).height() > $('footer').offset().top &&
      this.final === false &&
      this.flag === false &&
      this.scrollOn === true
    ) {
      this.loading = true;
      this.flag = true;
      this.getPlaylists();
    }
  }
  ngOnInit() {
    this.loading = true;
    this.getPlaylists();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }
  // get playlist list
  getPlaylists() {
    this.page += 1;
    this.apiService
      .callGetAPI(
        'v2/create_playlist?page=' + this.page,
        environment.userActionApiUrl
      )
      .subscribe(
        (data) => {
          for (const playlist of data['response'].my_playlist.data) {
            this.playlist.push(playlist);
            this.playlistFlag[playlist._id] = false;
          }
          if (this.playlist.length < 1) {
            this.noPlaylist = true;
          }
          this.final =
            data['response'].my_playlist.next_page_url === null ? true : false;
          this.loading = false;
          this.flag = false;
          this.scrollOn = true;
        },
        (error) => {
          this.apiService.failureResponse(error);
        }
      );
  }
  showPlaylistAction(id, event) {
    disableScroll.on(undefined, {
      disableWheel: true,
      disableScroll: true,
      disableKeys: false
    });
    for (let k = 0; k < this.playlist.length; k++) {
      if (id !== this.playlist[k]._id) {
        document
          .getElementById('drop' + this.playlist[k]._id)
          .classList.remove('show');
        this.cancelToRemove(this.playlist[k]._id);
      }
    }
    document.getElementById('drop' + id).classList.toggle('show');
    this.playlistDrop = event.target;
  }
  removePlaylist(id, i) {
    this.apiService
      .callDeleteAPI(
        'v2/create_playlist?playlist_id=' + id,
        environment.userActionApiUrl
      )
      .subscribe(
        (data) => {
          this.apiService.successResponse(data);
          this.playlist.splice(i, 1);
          if (this.playlist.length < 1) {
            this.noPlaylist = true;
          }
          disableScroll.off();
        },
        (error) => {
          this.apiService.failureResponse(error);
          disableScroll.off();
        }
      );
  }
  editPlaylistPopup(id, i) {
    disableScroll.on(undefined, {
      disableWheel: true,
      disableScroll: true,
      disableKeys: false
    });
    this.playlist_name = this.playlist[i].name;
    const elements = document.getElementById(id);
    elements.style.display = 'block';
    elements.style.visibility = 'visible';
    elements.style.opacity = '1';
    document.getElementById('playlist_name' + id).focus();
  }
  cancelToRemove(id) {
    disableScroll.off();
    const elements = document.getElementById(id);
    elements.style.display = '';
    elements.style.visibility = 'hidden';
    elements.style.opacity = '0';
  }
  updatePlaylist(id, i, f: FormGroupDirective) {
    if (this.playlist_name !== undefined && this.playlist_name !== '') {
      this.submitted = true;
      this.translate.get('MY_PLAYLIST.UPDATE_PLAYLIST').subscribe((res) => {
        this.buttonText = res + '...';
      });
      this.apiService
        .callPostAPI(
          'v2/create_playlist',
          { id: id, name: this.playlist_name, screen: this.userData['response'].screen },
          environment.userActionApiUrl
        )
        .subscribe(
          (data) => {
            this.playlist[i].name = this.playlist_name;
            this.playlist_name = '';
            f.resetForm();
            this.result = false;
            this.submitted = false;
            this.apiService.successResponse(data);
            const addPlaylistElement = document.getElementById(id);
            addPlaylistElement.classList.remove('open');
            const elements = document.getElementById(id);
            elements.style.display = '';
            elements.style.visibility = 'hidden';
            elements.style.opacity = '0';
            disableScroll.off();
            this.translate
              .get('MY_PLAYLIST.UPDATE_PLAYLIST')
              .subscribe((res) => {
                this.buttonText = res;
              });
          },
          (error) => {
            disableScroll.off();
            this.errors = this.apiService.failureResponse(error);
            this.submitted = false;
            this.result = false;
            this.translate
              .get('MY_PLAYLIST.UPDATE_PLAYLIST')
              .subscribe((res) => {
                this.buttonText = res;
              });
            if (this.errors.commonError) {
              this.result = true;
              this.success = this.errors.commonError;
              this.utilityService.VplyToaster();
            }
          }
        );
    } else {
      this.result = false;
      this.success = 'Playlist_name can not be empty';
    }
  }
  goToPlaylistDetail(slug, title) {
    this.router.navigate(['/my-playlist/' + title + '/' + slug]);
  }
  ngOnDestroy() {
    disableScroll.off();
  }

  VplyToasterHide(id) {
    const elements = document.getElementById(id);
    elements.classList.remove('block');
    elements.classList.add('none');
  }
}
