import { Pipe, PipeTransform } from '@angular/core';
import { VideoDurationValidation } from './utils/videodurationvalidation';

@Pipe({name: 'timetextformat', pure: false})
export class TimeTextFormat implements PipeTransform {
  constructor () { }

  transform(input) {
    if (input) {
      return VideoDurationValidation(input);
    }
  }
}
