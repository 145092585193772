import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignUpModule } from './modules/sign-up/sign-up.module';
import { ToastrModule } from 'ngx-toastr';
import { HeaderComponent } from './shared/components/header/header.component';
import { SubscriptionComponent } from './modules/subscription/subscription.component';

import { FooterComponent } from './shared/components/footer/footer.component';
import { environment } from '../environments/environment';
import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
import { SignInModule } from './modules/sign-in/sign-in.module';
import { ContactUsModule } from './modules/contact-us/contact-us.module';
import { ForgetPasswordModule } from './modules/forget-password/forget-password.module';
import { HomeComponent } from './modules/home/home.component';
import { BannerComponent } from './modules/home/banner/banner.component';
import { OwlModule } from 'ngx-owl-carousel';
import { SharedModule } from './shared/shared.module';
import { LazyLoadImagesModule } from 'ngx-lazy-load-images';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationComponent } from './shared/components/notification/notification.component';
import { WatchVideoComponent } from './modules/watch-video/watch-video.component';
import { EmbedComponent } from './modules/embed/embed.component';
import { PlayerComponent } from './modules/video-detail/player/player.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { UrlSerializer } from '@angular/router';
import { CustomUrlSerializer } from './CustomUrlSerializer';
import { WebseriesDetailComponent } from './modules/category/webseries-detail/webseries-detail.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ContentEditableFormDirective } from './core/directives/content-editable-form.directive';
import { PhoneNumberValidatorDirective } from './core/directives/phone-number-validator.directive';
import {ErrorsModule} from './core/errors/errors.module';
import { TrailerComponent } from './modules/video-detail/trailer/trailer.component';
import { SeasonComponent } from './modules/series-detail/season/season.component';
import { EpisodesComponent } from './modules/series-detail/season/episodes/episodes.component';
import { ManageScreenComponent } from './modules/profile/manage-screen/manage-screen.component';
import { MyAccountComponent } from './modules/profile/my-account/my-account.component';
import { FavoriteVideosComponent } from './modules/profile/favorite-videos/favorite-videos.component';
import { ProfileComponent } from './modules/profile/profile.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { SidebarMenuModule } from './modules/profile/sidebar-menu/sidebar-menu.module';
import { NgDatepickerModule } from 'ng2-datepicker';
import { EmailOrPhoneValidatorDirective } from './email-or-phone-validator.directive'; // Import the directive here


import { MatButtonModule } from
  '@angular/material/button';
import { MatButtonToggleModule } from
  '@angular/material/button-toggle';
import { MatDatepickerModule } from
  '@angular/material/datepicker';
import { MatInputModule } from
  '@angular/material/input';
import { MatFormFieldModule } from
  '@angular/material/form-field';
import { MatNativeDateModule } from
  '@angular/material/core';
import { ChangePasswordComponent } from './modules/profile/change-password/change-password.component';
import { HistoryComponent } from './modules/profile/history/history.component';
import { NotificationsComponent } from './modules/profile/notifications/notifications.component';
import { SavePlaylistComponent } from './modules/profile/save-playlist/save-playlist.component';
import { MyPlaylistComponent } from './modules/profile/my-playlist/my-playlist.component';
import { TransactionComponent } from './modules/profile/transaction/transaction.component';
import { PurchasedVideosComponent } from './modules/profile/purchased-videos/purchased-videos.component';
import { SettingsComponent } from './modules/profile/settings/settings.component';
import { SavedCardComponent } from './modules/profile/saved-card/saved-card.component';
import { VcallbackComponent } from './vcallback/vcallback.component';
import { PhoneLoginComponent } from './phone-login/phone-login.component';
// import { DeviceDetectorService } from 'ngx-device-detector';
import { DeviceDetectorModule } from 'ngx-device-detector';




export const firebaseConfig = {
  apiKey: "AIzaSyA2PAJikCZNdaf2NE-lHhNs_rpLjPYG-UQ",
  authDomain: "homitv-c9171.firebaseapp.com",
  databaseURL: "https://homitv-c9171-default-rtdb.firebaseio.com",
  projectId: "homitv-c9171",
  storageBucket: "homitv-c9171.appspot.com",
  messagingSenderId: "136707794634",
  appId: "1:136707794634:web:0a498ee3d28a13129cf633",
  measurementId: "G-6FKJN7YYZ2"
};
const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(environment.GoogleOAuthClientId)
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.FacebookAppId)
  }
]);

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    BannerComponent,
    NotificationComponent,
    WatchVideoComponent,
    WebseriesDetailComponent,
    PlayerComponent,
    EmbedComponent,
    ContentEditableFormDirective,
    PhoneNumberValidatorDirective,
    TrailerComponent,
    SeasonComponent,
    EpisodesComponent,
    ManageScreenComponent,
    MyAccountComponent,
    FavoriteVideosComponent,
    ChangePasswordComponent,
    HistoryComponent,
    NotificationsComponent,
    SavePlaylistComponent,
    MyPlaylistComponent,
    TransactionComponent,
    PurchasedVideosComponent,
    SettingsComponent,
    SavedCardComponent,
    ProfileComponent,
    VcallbackComponent,
    PhoneLoginComponent,
    EmailOrPhoneValidatorDirective
  ],
  imports: [
    BrowserModule,
    SignUpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgDatepickerModule,
    Ng2TelInputModule,
    LazyLoadImagesModule,
    SharedModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule,
    DeviceDetectorModule.forRoot(),
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true,
      closeButton: true,
      timeOut: 5000,
      autoDismiss: true,
      positionClass: 'toast-bottom-left'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    SignInModule,
    ForgetPasswordModule,
    SocialLoginModule,
    // ContactUsModule,
    OwlModule,
    SharedModule,
    AppRoutingModule,
    LazyLoadImagesModule,
    ErrorsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string,
    public translate: TranslateService
  ) {
    translate.setDefaultLang('en');
    translate.use('en');
  }
}
