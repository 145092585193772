import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private apiData = new BehaviorSubject<any>(null);
  public apiData$ = this.apiData.asObservable();
  private bannerData = new BehaviorSubject<any>(null);
  public bannerData$ = this.bannerData.asObservable();
  private newData = new BehaviorSubject<any>(null);
  public newData$ = this.newData.asObservable();
  private webseriesData = new BehaviorSubject<any>(null);
  public webseriesData$ = this.webseriesData.asObservable();
  public homeCategoryData;
  private mainmenuCategoryData = new BehaviorSubject<any>(null);
  public mainmenuCategoryData$ = this.mainmenuCategoryData.asObservable();
  constructor(private apiService: ApiService) {}

  fetchData() {
    return this.apiService
      .callGetAPI('v2/footer', environment.commonApiUrl)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  setData(data) {
    this.apiData.next(data);
  }

  fetchBanner() {
    this.apiService.callGetAPI('v2/home_page_banner').subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.bannerData.next(data['response'].banner);
          this.newData.next(data['response'].new);
          this.webseriesData.next(data['response'].webseries);
          const isBanner = data['response'].banner.total > 0 ? true : false;
          if (isBanner) {
            localStorage.setItem('isBanner', '1');
          } else {
            localStorage.setItem('isBanner', '0');
          }
        }
      },
      (error) => {
      }
    );
  }

  fetchMainmenuCategories() {
    return this.apiService
      .callGetAPI('v2/menu-categories', environment.commonApiUrl)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  setHomeCategory(data) {
    this.homeCategoryData = data;
  }

  getHomeCategory() {
    return this.homeCategoryData;
  }

  setMainMenuCategory(data) {
    this.mainmenuCategoryData.next(data);
  }
}
