import { Component, OnInit, AfterViewInit, AfterContentInit } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../core/auth/auth.service';
import { HeaderService } from '../../../core/services/header.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit, AfterContentInit {
  date = new Date();
  year = this.date.getFullYear();
  categories: any = [];
  userValues: string;
  is_subscribed: any;
  contact: any;
  cmsLink: any = [];

  site_link: any = [];
  language: any;
  footerFlag: any = false;
  buildno: any;
  cookie: any = false;
  constructor(private apiService: ApiService,
    private router: Router,
    private authService: AuthService,
    private translate: TranslateService,
    private headerService: HeaderService) {
  }

  ngOnInit() {
    if (localStorage.getItem('currentLanguage')) {
      this.language = localStorage.getItem('currentLanguage');
    } else {
      this.language = 'en';
    }
    // const footerHeight =  window.getComputedStyle(document.getElementById('footer'));
    // const fheight = footerHeight.getPropertyValue('height');
    // document.body.style.paddingBottom = fheight;

    if (document.cookie.indexOf("accepted_cookies=") < 0) {
      this.cookie = true;
    }

  }
  ngAfterViewInit() {
    this.getCategories();
  }
  ngAfterContentInit(): void {
    this.footerFlag = true;
  }
  check_subscribed(){
    this.userValues = localStorage.getItem('currentUser');
    if (this.userValues) {
      const userData = this.authService.decryption(this.userValues);
      this.is_subscribed = userData['response'].is_subscribed;
    }
    if(this.is_subscribed == 0){
      this.router.navigate(['subscription']);
    }
  }
  getCategories() {
    this.headerService.apiData$.subscribe(data => {
      if (data !== null) {
        this.categories = data['category'];
        this.contact = data['response'].address;
        this.cmsLink = data['response'].static_contents;
        this.site_link = data['response'].site_link;
      }
    });
    if (this.categories.length === 0) {
      setTimeout(() => {
        this.headerService.fetchMainmenuCategories().subscribe(
          (data) => {
            this.categories = data['response'].data;
          },
        );
      }, 1000);
    }
  }
  /**
   * Category Navigation
   * 1. Guest and subscriber access - all
   * 2. Unsubscribe access - only rental
   */
  categoryNavigation(cat) {
    this.userValues = localStorage.getItem('currentUser');
    this.is_subscribed = 0;
    if (this.userValues) {
      const userData = this.authService.decryption(this.userValues);
      this.is_subscribed = userData['response'].is_subscribed;
      if(this.is_subscribed == 0){
        if (cat.slug == "pay-per-view") {
          this.router.navigate(['category/' + cat.slug]);
        } else {
          this.router.navigate(['subscription']);
        }
      } else {
        // Navigation based on the web series
        if (cat.is_web_series) {
          this.router.navigate(['series/' + cat.slug]);
        } else {
          this.router.navigate(['category/' + cat.slug]);
        }
      }
    } else {
      // Navigation based on the web series
      if (cat.is_web_series) {
        this.router.navigate(['series/' + cat.slug]);
      } else {
        this.router.navigate(['category/' + cat.slug]);
      }
    }
  }

  google_play_clicked() {
    if (this.site_link.android_app_link) {
      const a = document.createElement('a');
      a.target="_blank";
      a.href = this.site_link.android_app_link ? this.site_link.android_app_link : undefined;
      a.click();
    }
  }
  app_store_clicked() {
    if (this.site_link.ios_app_link) {
      const a = document.createElement('a');
      a.target="_blank";
      a.href = this.site_link.ios_app_link ? this.site_link.ios_app_link : undefined;
      a.click();
    }
  }

  allowCookie(val){
    this.deleteCookie('accepted_cookies')
    const d = new Date();
    d.setTime(d.getTime() + (30*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    this.cookie = false;
    if(val === 1){
      document.cookie = "accepted_cookies=1;" + expires + ";path=/";
    } else {
      document.cookie = "accepted_cookies=0;" + expires + ";path=/";
    }
  }

  deleteCookie(cname) {
    const d = new Date();
    d.setTime(d.getTime() + (24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=;" + expires + ";path=/";
  }
}
